import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { baseUrl } from "./baseURL";

function AllDetails() {
  useEffect(()=>{
    if(localStorage.getItem("isLogin")!=="yes"){
      navigate("/")
    }
  },[])
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);
  useEffect(()=>{
    const isLogin = sessionStorage.getItem("isLogin")
    if(!isLogin){
      navigate('/login')
    }
  },[])

  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);

  const getData = () => {
    axios
      .get(baseUrl+"details")
      .then((res) => {
        const item = res.data.data.reduce((a, c) => [...a, ...c.insurance.map((i) => i)], [])
        .filter((j) => j.type === "medical")
      setData(item)
      setData1(item)
      });
  };

  const filter = (x) => {
    const list = data1.filter((i) => i.name.toLowerCase().includes(x) );
    setData(list);
  };
  
  const filter1 = (x) => {
    const list = data1.filter((i) => i.renewalDate.includes(x));
    setData(list);
  };
  

  
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Sidebar />
      <div
        style={{
          width: "80%",   paddingRight:"0.5%"     }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            // justifyContent: "space-around",
            height: "8vh",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <label>Name:</label>
            <input
              onChange={(e) => {
                filter(e.target.value);
              }}
              placeholder="Name"
              style={{
                border: "1px solid grey",
                borderRadius: 5,
                height: 30,
                width: 200,
              }}
            ></input>
            <label>Month:</label>
              <select 
        onChange={(e)=>filter1(e.target.value)}
        style={{
          border: "1px solid grey",
          borderRadius: 5,
          height: 30,
          width: 200,
        }}>
           <option value={"-"}>All</option>
            <option value={"-01-"}>January</option>
            <option value={"-02-"}>February</option>
            <option value={"-03-"}>March</option>
            <option value={"-04-"}>April</option>
            <option value={"-05-"}>May</option>
            <option value={"-06-"}>June</option>
            <option value={"-07-"}>July</option>
            <option value={"-08-"}>August</option>
            <option value={"-09-"}>September</option>
            <option value={"-10-"}>October</option>
            <option value={"-11-"}>November</option>
            <option value={"-12-"}>December</option>
        </select>
          </div>
          
        </div>
        <table
          style={{ width: "100%", backgroundColor: "white", color: "#05386B" }}
          class="table text-nowrap"
        >
          <thead style={{ backgroundColor: "#E7393E", color: "whitesmoke" }}>
            <tr>
              <th scope="col">S No.</th>
              <th scope="col">Name</th>
              <th scope="col"> City</th>
              <th scope="col">Renewal Date</th>
             
              <th scope="col">Email</th>
              <th scope="col">Adult</th>
              <th scope="col">Child</th>
              <th scope="col">Sum assored</th>
            </tr>
          </thead>
          <tbody>
            {data
              .map((i, n) => (
                <tr
                  style={{
                    fontWeight: "bold",
                    backgroundColor: n % 2 === 0 ? "#EDF5E1" : null,
                  }}
                >
                  <th scope="row">{n + 1}</th>
                  <td>{i?.name}</td>
                  <td>{i?.city}</td>
                  <td>{i?.renewalDate?.split("-").reverse().join("/")}</td>              
                  <td>{i?.email}</td>
                  <td>{i?.adult}</td>
                  <td>{i?.child}</td>
                  <td>{i?.sumAssored}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AllDetails;
