import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import man from '../man.png'
import woman from '../woman.png'
import boy from '../boy.png'
import girl from '../girl.png'

function MemberDetails() {

  useEffect(()=>{
    if(localStorage.getItem("isLogin")!=="yes"){
      navigate("/")
    }
  },[])
  useEffect(()=>{
    const isLogin = sessionStorage.getItem("isLogin")
    if(!isLogin){
      navigate('/login')
    }
  },[])
  const list = localStorage.getItem("memberDetails");
  const list2 = JSON.parse(list);
  const [data, setData] = useState(list2);
  const navigate = useNavigate();

  const scroll = () => {
    const section = document.querySelector("#familyhead");
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const scrollrelation = (n) => {
    const section = document.querySelector(`#relation${n}`);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <PrintProvider>
      <div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "8vh",
            backgroundColor: "#E9393E",
            color: "white",
            justifyContent: "space-around",
            position: "fixed",
            alignItems:"center",
          }}
        >
          <h2
            onClick={() => scroll()}
            style={{ cursor: "pointer", fontFamily: "Georgia" }}
          >
            Family head
          </h2>
          {data?.members.map((i, n) => (
            <h2
              style={{ cursor: "pointer", fontFamily: "Georgia" }}
              onClick={() => scrollrelation(n)}
            >
              {i.relation}
            </h2>
          ))}
        </div>

        <Print single name="foo">
          <div
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center", 
              // backgroundColor: "#5CDB95",
            }}
          >
            <div style={{ width: "100%", height: "90vh" , marginLeft:50 }}>
              <div
                id="familyhead"
                style={{ width: "100%", height: "10vh" }}
              ></div>
              <h2
                style={{
                  fontSize: 20,
                  fontWeight: "bolder",
                  textDecoration: "underline",
                  color: "#E7393E", textAlign:"center"
                }}
              >
                Family head
              </h2>
              <div style={{display:"flex", justifyContent:"space-evenly"}}>
              <img src={man} style={{weight:50, height:400}}/>
              <div
                style={{
                  width: "65%",
                  height: "55vh",
                  boxShadow: "2px 2px 5px grey",
                  marginTop: 30, 
                  marginRight: 20,
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  backgroundColor: "white", 
                }}
              >
               <div className="arrow"></div>
               
                <div
                  style={{
                    width: "40%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    borderRight: ".2px solid black",
                    paddingRight: 25,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Name:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>
                        {data.firstName} {data.middleName} {data.lastName}
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Gender:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.gender}</label>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Date of birth:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.dob}</label>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Height:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.height}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Weight:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.weight}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Marital status:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.maritalStatus}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Aadhar No.:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.aadharNumber}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Pan No.:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.panNumber}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Education:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.education}</label>
                    </div>{" "}
                  </div>
                </div>
                <div
                  style={{
                    width: "40%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Monthly Income:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.monthlyIncome}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>occupation:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.occupation}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Country:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.country}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>State:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.state}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>City</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.city}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Pincode</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.pincode}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Address:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.address}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Email:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.email}</label>
                    </div>{" "}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label>Number:</label>
                    <div style={{ width: "60%", justifyContent: "flex-start" }}>
                      <label>{data.number}</label>
                    </div>{" "}
                  </div>
                </div>
                <i
                  onClick={() => {
                    navigate("/EditDetails");
                    localStorage.setItem("editMembers", JSON.stringify);
                  }}
                  class="fa fa-pencil"
                  style={{ color: "red", cursor: "pointer", marginTop:10}}
                  aria-hidden="true"
                ></i>
              </div>
              </div>
            </div>


                    
            {data?.members.map((i, n) => (
              <div style={{ width: "100%", height: "90vh",}}>
                <div
                  id={`relation${n}`}
                  style={{ width: "100%", height: "10vh" }}
                ></div>
                  <h2
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bolder",
                      textDecoration: "underline",
                      color: "#E7393E",
                    }}
                  >
                    {i.relation}
                  </h2>
                  <div style={{display:"flex", justifyContent:"space-evenly", width:"100%"}}>
                  <img src={i.relation==="son"?boy:i.relation==="daughter"?girl:i.relation==="spouse"?woman:null} style={{weight:50, height:400}}/>
                  <div
                    style={{
                      width: "65%",
                      height: "55vh",
                      boxShadow: "2px 2px 5px grey",
                      marginTop: 30,
                      marginRight: 20,
                      borderRadius: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",  backgroundColor: "white"
                    }}
                  >
                                  <div className="arrow"></div>
                    <div
                      style={{
                        width: "40%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        borderRight: ".2px solid black",
                        paddingRight: 25,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Name:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>
                            {i.firstName}
                            {i.middleName}
                            {i.lastName}
                          </label>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Gender:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.gender}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Date of birth:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.dob}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Height:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.height}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Weight:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.weight}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Marital status:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.maritalStatus}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Aadhar No.:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.aadharNumber}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Pan No.:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.panNumber}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Education:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.education}</label>
                        </div>{" "}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "40%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        borderRadius: 10,
                      }}
                    >
                       <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Monthly Income:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.monthlyIncome}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>occupation:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.occupation}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Country:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.country}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>State:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.state}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>City</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.city}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Pincode</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.pincode}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Address:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.address}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Email:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.email}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Number:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.number}</label>
                        </div>{" "}
                      </div>
                    </div>
                    <i
                      onClick={() => {
                        navigate("/EditDetails");
                        localStorage.setItem("editMembers", JSON.stringify);
                      }}
                      class="fa fa-pencil"
                      style={{ color: "red", cursor: "pointer", marginTop:10 }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>
            ))}
            <br />

              {data?.insurance.length&&
              <h2 style={{color:"#E7393E"}}>Insurance</h2>
              }
            {data?.insurance.map((i, n) => {
            if(i.type==="motor"){
            return(
            <>
                      <h2
                      style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bolder",
                      textDecoration: "underline",
                      color: "#E7393E",
                    }}
                  >
                    Motor
                  </h2>
            <div style={{display:"flex", justifyContent:"space-evenly", width:"100%"}}>
                  <div style={{weight:50, height:400, }}/>
                  <div
                    style={{
                      width: "65%",
                      height: "55vh",
                      boxShadow: "2px 2px 5px grey",
                      marginTop: 30,
                      marginRight: 20,
                      borderRadius: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",  backgroundColor: "white"
                    }}
                  >
                    <div className="arrow"></div>
                    <div
                      style={{
                        width: "80%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        paddingRight: 25,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Name:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>
                            {i.name}
                          </label>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>RTO City:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.rtoCity}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Registration No.:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.registrationNumber}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Renewal Date:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.renewalDate}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Vehicle Type.:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.vehicleType}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Policy No.:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.policyNumber}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>EmailID:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.email}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Phone Number:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.phoneNumber}</label>
                        </div>{" "}
                      </div>
                    
                    </div>
                   
                    <i
                      onClick={() => {
                        navigate("/EditDetails");
                        localStorage.setItem("editMembers", JSON.stringify);
                      }}
                      class="fa fa-pencil"
                      style={{ color: "red", cursor: "pointer", marginTop:10 }}
                      aria-hidden="true"
                    ></i>
                  </div>
            </div>
            </>)} else  if(i.type==="medical"){
            return(
            <>
            
            
            <h2
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bolder",
                      textDecoration: "underline",
                      color: "#E7393E",
                    }}
                  >
                    Medical
                  </h2>
            <div style={{display:"flex", justifyContent:"space-evenly", width:"100%"}}>
                  <div style={{weight:50, height:400, }}/>
                  <div
                    style={{
                      width: "65%",
                      height: "55vh",
                      boxShadow: "2px 2px 5px grey",
                      marginTop: 30,
                      marginRight: 20,
                      borderRadius: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",  backgroundColor: "white"
                    }}
                  >
                    <div className="arrow"></div>
                    <div
                      style={{
                        width: "80%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        paddingRight: 25,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Name:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>
                            {i.name}
                          </label>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>City:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.city}</label>
                        </div>{" "}
                      </div>
                      
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Renewal Date:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.renewalDate}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>EmailID:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.email}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Adult:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.adult}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Child:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.child}</label>
                        </div>{" "}
                      </div>
                      
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Sum assored:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.sumAssored}</label>
                        </div>{" "}
                      </div>
                    
                    </div>
                   
                    <i
                      onClick={() => {
                        navigate("/EditDetails");
                        localStorage.setItem("editMembers", JSON.stringify);
                      }}
                      class="fa fa-pencil"
                      style={{ color: "red", cursor: "pointer", marginTop:10 }}
                      aria-hidden="true"
                    ></i>
                  </div>
            </div>
            </>)} else  if(i.type==="life"){
            return(
            <>
                
                
                
                <h2
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bolder",
                      textDecoration: "underline",
                      color: "#E7393E",
                    }}
                  >
                    Life
                  </h2>
            <div style={{display:"flex", justifyContent:"space-evenly", width:"100%"}}>
                  <div style={{weight:50, height:400, }}/>
                  <div
                    style={{
                      width: "65%",
                      height: "55vh",
                      boxShadow: "2px 2px 5px grey",
                      marginTop: 30,
                      marginRight: 20,
                      borderRadius: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",  backgroundColor: "white"
                    }}
                  >
                    <div className="arrow"></div>
                    <div
                      style={{
                        width: "80%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        paddingRight: 25,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Name:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>
                            {i.name}
                          </label>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Plan/Term:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.plan}</label>
                        </div>{" "}
                      </div>
                      
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Premium payment mode </label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.premiumMode}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Policy No.:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.policyNumber}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Nominee name:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.NomineeName}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Phone Number:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.PhoneNumber}</label>
                        </div>{" "}
                      </div>
                      
                      
                    
                    </div>
                   
                    <i
                      onClick={() => {
                        navigate("/EditDetails");
                        localStorage.setItem("editMembers", JSON.stringify);
                      }}
                      class="fa fa-pencil"
                      style={{ color: "red", cursor: "pointer", marginTop:10 }}
                      aria-hidden="true"
                    ></i>
                  </div>
            </div></>)} else  if(i.type==="fire"){
            return(
            <>
                  
                  <h2
                    style={{
                      textAlign: "center",
                      fontSize: 20,
                      fontWeight: "bolder",
                      textDecoration: "underline",
                      color: "#E7393E",
                    }}
                  >
                    Fire
                  </h2>
            <div style={{display:"flex", justifyContent:"space-evenly", width:"100%"}}>
                  <div style={{weight:50, height:400, }}/>
                  <div
                    style={{
                      width: "65%",
                      height: "55vh",
                      boxShadow: "2px 2px 5px grey",
                      marginTop: 30,
                      marginRight: 20,
                      borderRadius: 10,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-evenly",  backgroundColor: "white"
                    }}
                  >
                    <div className="arrow"></div>
                    <div
                      style={{
                        width: "80%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        paddingRight: 25,
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Name:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>
                            {i.name}
                          </label>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Renewal Date:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.renewalDate}</label>
                        </div>{" "}
                      </div>
                      
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Policy NO: </label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.policyNumber}</label>
                        </div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label>Phone Number:</label>
                        <div
                          style={{ width: "60%", justifyContent: "flex-start" }}
                        >
                          <label>{i.phoneNumber}</label>
                        </div>{" "}
                      </div>
                      
                      
                    
                    </div>
                   
                    <i
                      onClick={() => {
                        navigate("/EditDetails");
                        localStorage.setItem("editMembers", JSON.stringify);
                      }}
                      class="fa fa-pencil"
                      style={{ color: "red", cursor: "pointer", marginTop:10 }}
                      aria-hidden="true"
                    ></i>
                  </div>
            </div>

            </>)}
            })}


          </div>
        </Print>
        <button style={{width:"10%", height:"6vh", backgroundColor:"#E7393E", color:"white", border:"none", borderRadius:5 }} onClick={() => window.print()}>download</button>
      </div>
    </PrintProvider>
  );
}

export default MemberDetails;
