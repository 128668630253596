import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'

function GovScheme() {
    useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
        <Header/>
        <div style={{width:"100%", height:"20vh"}}></div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>PRADHAN MANTRI FASAL BIMA YOJANA</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>In April, 2016, the Government of India had launched Pradhan Mantri Fasal Bima Yojana(PMFBY) after rolling back the earlier insurance schemes viz. National Agriculture Insurance Scheme (NAIS), Weather-based Crop Insurance scheme and Modified National Agricultural Insurance Scheme (MNAIS). Thus, at present, PMFBY is the flagship scheme of the government for agricultural insurance in India.</label><br/><br/>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>BENEFITS OF PRADHAN MANTRI FASAL BIMA YOJANA</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>• Farmer's contribution to premium is reduced significantly i.e. 2% for Kharif crops, 1.5% for Rabi crops and 5% for Annual and Commercial crops.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>• Provision to assess the losses individually in case of localized perils such as hailstorm, inundation and landslide.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>• Assessment of yield loss on individual plot basis in case of occurrence of cyclone, cyclonic rains and unseasonal rains throughout the country resulting in damage to harvested crop lying in the field in 'cut and spread' condition up to maximum period of two weeks (14 days) from harvesting for the sole purpose of drying.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>• On-account claim payment is made to the farmer in case of prevented sowing and localized losses.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>• The use of technology will be encouraged to a great extent under this scheme. Smart phones will be used to capture and upload data of crop cutting to reduce the delays in claim payment to farmers. Remote sensing will also be used under this scheme to reduce the number of crop cutting experiments.</label><br/><br/>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div style={{width:"90%", marginLeft:"5%", minHeight:"20vh", textAlign:"center", backgroundColor:"#E7393E", color:"White", borderRadius:10}}>
            <br/>
            <br/>
            <label style={{fontSize:40,}}>PRADHAN MANTRI SURAKSHA BIMA YOJANA</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify", paddingLeft:"5%", paddingRight:"5%"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Insurance for every Indian is a call of the times! With Pradhan Mantri Suraksha Bima Yojana, you can now call yourself ‘insured’ and with pride too! Inclusive, affordable insurance that provides substantial coverage- this is the common man’s umbrella of protection against accidental death or disability. Want to know more? Read on.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>First, a behind the scenes look into the rationale behind this ambitious, nation-wide insurance plan. Launched in 2015, to enable the benefits of financial inclusion to reach the lowest rung of Indian society, the Pradhan Mantri Suraksha Bima Yojana (PMSBY) provides accidental insurance cover at a minimal cost of Re. 1 per month. Looked at from a macro perspective, this plan is designed to mobilize financial resources to provide equitable growth opportunities for rural and semi-rural communities through adequate insurance.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>How does that benefit consumers, you ask? Well, by leveraging the basic principle of insurance, i.e spreading risk across a large number of contributors, Pradhan Mantri Suraksha Bima Yojana provides death and disability coverage at a nominal fee of just Re. 12/- per annum, Meant especially for consumers in rural areas where seasonal employment and inadequate medical services may deny victims of accidents access to affordable and timely medical care, Pradhan Mantri Suraksha Bima Yojana remains open to all Indian citizens between the ages of 18 and 70.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Just like a crop failure, an accident can severely limit your ability to earn an income. The cascading effect this can have on your family’s fortunes can potentially be felt for generations in the form of mounting debt and financial uncertainty. With Pradhan Mantri Suraksha Bima Yojana, you can be assured that your financial needs are taken care of, in the event of an accident, at an affordable cost while preventing the accrual of debt related to medical expenses.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Pradhan Mantri Suraksha Bima Yojana is part of a wider social movement aimed at reducing exposure to risk, providing financial redundancy and bridging the urban-rural divide in terms of healthcare and rehabilitation costs.</label><br/><br/>
              </div>
         </div>

        <Footer/>
    </div>
  )
}

export default GovScheme