import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import stars from '../stars.png'
import hand from '../hand.png'
import todo from '../todo.png'

function EVInsurance() {
    useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
        <Header/>
        <div style={{width:"100%", height:"20vh"}}></div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>ELECTRIC BIKE INSURANCE</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>With the industrial revolution, technological advancements have taken the human species up to new horizons. Yet, we cannot deny the fact that these come at a cost. Not every cost is calculated only in monetary terms.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>As we all are aware of the increasing global warming and the pollution level that is increasing day by day. Natural resources are gradually depleting. So, renewable energy is one alternative that should be looked upon to fuel technological advancements. Adhering to the same, the automobile industry in India is looking at electricity to charge both the four and two-wheelers. </label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>The future of the two-wheeler industry is the electric bike. Hence, electric bike insurance in India is indeed a progressive step in the long run. With all of these in place, the e-bikes introduction has also impacted the Indian insurance industry. To know more about insurance for e-bikes in India, read ahead!</label><br/><br/>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>BUY ELECTRIC BIKE INSURANCE ONLINE</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Anyone who has or intends to buy an electric bike should also consider buying the electric two wheeler insurance policy. This is important not just for your financial safety but also because the repair or replacement costs of electric bikes are on the higher side.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>In India, road safety is uncertain. Insurance for e-bikes is a responsibility for your safety and responsibility towards others. Now you can easily avail of insurance coverage online. Here are the steps toward buying electric bike insurance online: .</label><br/><br/>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div style={{width:"90%", marginLeft:"5%", minHeight:"20vh", textAlign:"center", backgroundColor:"#E7393E", color:"White", borderRadius:10}}>
            <br/>
            <br/>
            <label style={{fontSize:40,}}>ELECTRIC CAR INSURANCE</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify", paddingLeft:"5%", paddingRight:"5%"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>One of the most exciting entrants in the vehicle industry, at present, are electric cars. Once a concept, they have now become mainstream.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>More and more people are now opting for it. Accessibility is said to be one of the reasons for more consumers opting for electric vehicles, especially cars.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Another reason may be that these vehicles are believed to be environmentally friendly.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Also, there are some financial benefits to buying electric vehicles. One such benefit is that you end up saving significantly on fuel costs.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>You may also be offered some incentives for buying these vehicles.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>There are primarily four types of electric vehicles available in the market today. These are:</label><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>✓ Battery electric vehicles</label><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>✓ Hybrid electric vehicles</label><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>✓ Plug-in hybrid electric vehicles</label><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>✓ Fuel cell electric vehicles</label><br/>
            </div>
            <br/>
            <br/>
            <br/>
            <label style={{fontSize:40}}>CAPTIVATE YOURSELF WITH THE BENEFITS EV CAR INSURANCE</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify", paddingLeft:"5%", paddingRight:"5%"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Is it necessary for you to get an electric car insurance policy?</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>According to the Motor Vehicles Act of 1988, it is mandatory for you to get a third-party liability for your car. The requirement extends to your electric car as well.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>However, it is not mandatory to get comprehensive coverage for your vehicle.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Nonetheless, it is advisable to get it, especially if you seek financial coverage against the possibility of an unfortunate accident.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>To completely grasp the importance of car insurance for an EV, you need to understand how insurance works. Suppose any damage were to befall your car.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Repairs would cost a significant amount and, possibly, burn a hole in your savings.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>When you have insurance, your savings remain relatively unaffected.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>The insurance provider, based on your policy details, will provide you coverage for the cost of repairs. Similarly, a third-party policy will protect you against having to pay for any damages to another person’s vehicle.</label><br/><br/>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:25, color:"#E7393E"}}>THERE ARE MULTIPLE ADVANTAGES OF CHOOSING INSURANCE SPECIALIST INDIA AS YOUR ELECTRIC COMMERCIAL VEHICLE INSURANCE PARTNER FOR BELOW REASONS</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>✓ Peace of mind</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>✓ Quick claim settlement</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>✓ Cashless claim service</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>✓ Large garages network</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>✓ Enhance digital experience</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>✓ Comprehensive coverage</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>✓ Specialized EV helpline    </label><br/><br/>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:25, color:"#E7393E"}}>4 WHEELS OF SUPPORT WITH A COMMERCIAL EV POLICY FROM INSURANCE SPECIALIST INDIA</label>
            <br/>
            <br/>
            <div style={{width:"70%", minHeight:"50vh", marginLeft:"15%", display:"flex", flexWrap:"wrap", justifyContent:"space-between", alignItems:"center"}}>
                <div  className='EV-red-div' style={{width:"48%", height:80, backgroundColor:"#E7393E", borderRadius:10,display:"flex"}}>
                    <div style={{height:80, width:"30%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div style={{height:45, width:45,borderRadius:"50%", border:"1px solid white"}}>
                        <img  src='https://www.bajajallianz.com/content/dam/bagic/criti-care/base-coverage.png'></img>
                        </div>
                    </div>
                    <div style={{height:80,color:"white", width:"70%",display:"flex", justifyContent:"start", alignItems:"center"}}>
                        <label>Roadside Assistance Cover</label>
                    </div>
                </div>
                <div  className='EV-red-div' style={{width:"48%", height:80, backgroundColor:"#E7393E", borderRadius:10,display:"flex"}}>
                    <div style={{height:80, width:"30%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div style={{height:45, width:45,borderRadius:"50%", border:"1px solid white",display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <img style={{height:35}} src={stars}></img>
                        </div>
                    </div>
                    <div style={{height:80,color:"white", width:"70%",display:"flex", justifyContent:"start", alignItems:"center"}}>
                        <label>Dedicated EV helpline</label>
                    </div>
                </div>
                <div  className='EV-red-div' style={{width:"48%", height:80, backgroundColor:"#E7393E", borderRadius:10,display:"flex"}}>
                    <div style={{height:80, width:"30%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div style={{height:45, width:45,borderRadius:"50%", border:"1px solid white",display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <img style={{height:28}} src={hand}></img>
                        </div>
                    </div>
                    <div style={{height:80,color:"white", width:"70%",display:"flex", justifyContent:"start", alignItems:"center"}}>
                        <label>Installation of wall box for charging</label>
                    </div>
                </div>
                <div  className='EV-red-div' style={{width:"48%", height:80, backgroundColor:"#E7393E", borderRadius:10,display:"flex"}}>
                    <div style={{height:80, width:"30%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div style={{height:45, width:45,borderRadius:"50%", border:"1px solid white",display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <img style={{height:28}} src={todo}></img>
                        </div>
                    </div>
                    <div style={{height:80,color:"white", width:"70%",display:"flex", justifyContent:"start", alignItems:"center"}}>
                        <label>On-site Charging</label>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default EVInsurance