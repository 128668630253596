import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'

function CommercialInsurance() {
    useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
        <Header/>
        <div style={{width:"100%", height:"20vh"}}></div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>PROPERTY INSURANCE</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>When you’re running a business, your business property, where you conduct your business and store your inventory, is your base camp. It all starts and ends there. It is home to all your assets, from plants and machinery to computers and printers, and it’s where you and your employees brainstorm, work hard and make the magic happen. It is central to the existence, survival and growth of your business.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Any disturbance, major or minor, can have a cascading effect on the daily functioning of your company that can be catastrophic. For instance, a fire will not only damage the building but also the stock and machinery and equipment inside it. This would have a huge financial impact, interrupt the daily functioning of your business and may ruin relationships with clients. </label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Most companies in such a situation would find it hard to manage the disaster and get back on their feet. The worst part is, no matter how many precautions you take, such unfortunate events have a way of sneaking up on you at the most unexpected times.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>We know you’ve built your business by putting in it everything that you’ve got, and we think it’s unfair and quite unnecessary for all of that to go to waste because of such a happening. Hence, we offer you Bajaj Allianz Property Insurance. </label><br/><br/>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>MARINE INSURANCE</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Placing an order for your favourite football club’s branded merchandise may give the diehard fan in you a cause for celebration. It also sets in motion a complex chain of events that spans an elaborate network of manufacturers, logistics agencies and of course, insurance companies. What does insurance have to do with e-commerce, you ask? Well, Marine Insurance underwrites billions of dollars worth of goods- from the moment they leave the factory where they’re produced until they finally arrive at the buyer’s warehouse.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>The growing risk of international piracy, road accidents, collisions between merchant tankers due to human error, and even damage to stock during loading or unloading of cargo, is becoming all too common.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Marine Insurance gives a halo of protection to goods as soon as they leave your factory or warehouse until they finally arrive at their destination, across land, sea or air.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>The flow of global trade requires businesses and corporations to use Marine Insurance to mitigate risks and build redundancy into their distribution networks.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>For emerging economies like India, crude oil and high end imports depend on the uninterrupted flow of goods through shipping lanes crisscrossing vast swathes of ocean. With the government’s push towards manufacturing gaining steam, generating greater revenues necessitates diversification in terms of market reach and penetration.Bajaj Allianz has been the preferred Marine Insurance solutions provider to a host of Indian companies across a variety of sectors. By cushioning industry from a range of risks at sea, we give companies and their investors’ greater confidence to set and achieve new growth targets.</label><br/><br/>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>LIABILITY INSURANCE</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>With the ever-emerging business risks, your business is like a wounded person, left smack in the middle of a sea of sharks. They smell blood and they don’t understand the concept of mercy.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>No matter how solid your business structure is, in the dynamic business environment, its vulnerable to a variety of risks. Many claims can be made against your business, right from a customer to an employee.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Such claims can make your business suffer a catastrophic loss, restrict your cashflow and disturb the daily functioning of your business. But of course, you knew all about the risks when you started out and it didn’t stop you, so why should it now?</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>What you need is a reliable long-term partner with global reach and expertise who you can fall back on when unexpected accidents occur and we, at Bajaj Allianz, with our Liability Insurance Policy say, ‘Challenge Accepted!’ </label><br/><br/>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>FINANCIAL LINES INSURANCE</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>The growth and success of your business is not going to be linear and that’s okay. The graph is going to have its fair share of downward spikes and while there’s nothing you can do to do away with that, you can take measures to ensure that they are not too frequent or sharp. We, at Bajaj Allianz, with our Financial Lines Insurance Policy help you do just that.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>In today’s highly competitive market and increasingly litigious world, having the back up of Financial Lines Insurance is key to not just the steadiness of your business but also its survival.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>A suit filed against your company by any of those you build relationships with, from employees to customers and everyone in between, can have a cascading effect on the performance and profitability of your business.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>It’s not uncommon for a company to be sued by their begrudged employee on grounds of wrongful termination or by their client for breach of confidentiality. Such claims can have catastrophic effects on your company.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Our Financial Lines Insurance Policy is specifically designed, after having considered the nuances of all industries and professions, to take care of such varied claims. Our services are tailor-made to suit the needs of all companies, ranging from the largest global multi-nationals to small- and medium-sized enterprises.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>We cover most industrial sectors and have a track record of providing Financial Lines Insurance solutions for general commercial clients.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>We understand that the market is fast-moving and requires a responsive and up-to-date approach based on a detailed understanding of emerging risks and legal developments. Our people and processes, hence, are aligned to offer you the same.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Our industry experience, teams of expert underwriters and our ability to adapt to the dynamic markets is what helps us assure you that we’ll be there for you when you need us.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Since your business (and its different aspects) is vulnerable to a variety of risks, we offer a range of need-specific Financial Lines Insurance solutions. </label><br/><br/>
            </div>
        </div>
        <br/>
        <br/>
        <br/>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>ENGINEERING INSURANCE</label>
            <br/>
            <br/>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>The engineering and construction industries are a whole different thing and have very specific needs. We, at Bajaj Allianz General Insurance, understand that and provide specialised covers for all your engineering and construction insurance needs.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>If you’re wondering what is engineering insurance cover, you’re at the right place. Engineering Insurance Policies are those that financially safeguard you against the volatile risks of your industry. Scope of engineering insurance includes everything, from risks associated with machinery while it’s being installed, at work or at rest to business interruption.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>At Bajaj Allianz, our risk consulting, claims and underwriting teams not only bring with them the expertise and practical experience of managing the largest engineering risks and construction projects, but also a sense of understanding and care of what your business means to you.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>We deliver the services of our Engineering Insurance Policies by carefully ensuring close liaison between all parties, not only within our own claims, risk consulting and underwriting teams, but also with the client and the client's professional team through our key account management philosophy.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>This philosophy is founded on the shared expertise of our expert teams: covering specific engineering risk classes such as power and utilities, oil and gas, constructions, heavy industries, major heavy civil engineering projects, and transportation systems.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>We emphasize open dialogue with our clients and their advisors, ensuring a flexible approach and mutual understanding between all parties. All the types of Engineering Insurance Policies we offer are carefully designed and highly customised to suit the nature of your business.</label><br/><br/>
            </div>
        </div>
        
        <Footer/>
    </div>
  )
}

export default CommercialInsurance