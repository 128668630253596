import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'

function TravelInsurance() {
    useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
        <Header/>
        <div style={{width:"100%", height:"15vh",}}></div>
        <div style={{width:"80%", minHeight:"10vh", marginLeft:"10%", textAlign:'center'}}>
            <label style={{fontSize:40, color:"#E7393E"}}>Travel Insurance</label>
            <div style={{textAlign:"justify"}}><br/><br/>  
                <label style={{fontSize:17, fontWeight:"lighter"}}>A hassle-free travel is a dream of many people. Agreed? Travelling becomes all the more convenient and stress-free when it is secured in the best possible way as there’s a possibility that an unexpected event may alter all your travel plans at any point in the journey. If truth be told, then these unfortunate events may not only affect travellers emotionally and physically but also financially. So, to help avid travellers like you stay protected against uncertainties while travelling to your favourite destinations, purchasing travel insurance in advance is the most effective solution.</label>
            </div>
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", marginTop:"5%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>Benefits of travel insurance </label>
            <div style={{textAlign:"justify"}}><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>No matter what your travel destination is, having travel insurance is unarguably an essential need. Travel insurance makes everything so easy and organized that travelers must avail of the same, first and foremost. It covers unexpected medical expenses and offer protection against delayed flights and trip cancellation. Furthermore, travel insurance plans also cover loss of checked in baggage and passport which offers peace of mind to the traveler. It is important to go through the plan details and check all the benefits that are being offered by the travel insurance plan that you are buying.</label>
                <br/><br/>
                <div style={{ textAlign:"start"}}>
                <b style={{fontSize:17}}>• &nbsp; Why is travel insurance important?</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>Travel insurance is important to safeguard your trip against any mishappenings. From compensating for trip cancellation to reimbursing for the medical expenses incurred during travelling, travel insurance is extremely important in today’s time.</label>
            </div><br/>
                <div style={{ textAlign:"start"}}>
                <b style={{fontSize:17}}>• &nbsp; How do I find the right travel insurance?</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>Depending on your budget and travel needs, compare the different travel insurance plans offered by the top insurance companies and pick the one that matches all your needs.</label>
            </div><br/>
                <div style={{ textAlign:"start"}}>
                <b style={{fontSize:17}}>• &nbsp; Is travel insurance worth the cost?</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>Yes, travel insurance is a must, especially for those who travel on a regular basis. Moreover, it also acts as a perfect safety net for travellers.</label>
            </div><br/>
                <div style={{ textAlign:"start"}}>
                <b style={{fontSize:17}}>• &nbsp; What is international travel insurance?</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>International travel insurance offers a range of coverage such as medical expenses, flight hijacks and more when you are travelling outside your country.</label>
            </div><br/>
                <div style={{ textAlign:"start"}}>
                <b style={{fontSize:17}}>• &nbsp; Are pre-existing diseases covered in a travel insurance plan?</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>No, almost all the insurance companies in India do not offer compensation for pre-existing diseases. So, if any difficulty arises due to pre-existing diseases during your trip, then that won’t be reimbursed.</label>
            </div><br/>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default TravelInsurance