import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import wallet from '../wallet.png'
import heart from '../heart.png'
import plus from '../plus.png'


function HealthInsurance() {
    useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
        <Header/>
        <div style={{width:"100%", height:"20vh"}}></div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{color:"#E9373E", fontSize:30}}>What is Health Insurance?</label>    
                <div className='health-div1' style={{display:"flex", marginTop:"5%"}}>
                 <div className='health-div1-sub' style={{width:"70%", textAlign:"justify"}}>
                     <label style={{fontSize:18, fontWeight:"lighter"}}>It is an agreement between the insurer and the policyholder whereby an insurance company agrees to 
                     reimburse for medical costs incurred by the policyholder during the tenure of the policy. According to 
                     the policy terms, the insured may incur medical expenses if they get ill or meet an unfortunate accident 
                     that leads to treatment at the hospital. To avail of the coverage benefits of the policy, the policyholder 
                     must pay a specific amount periodically, called a premium. The premium is determined by the insurance company 
                     and must be paid by the policyholders without any fail either monthly, quarterly, half-yearly or annually.
                     </label>
                 </div>
                 <div style={{width:"30%"}}>
                     <img src="https://static.insurancedekho.com/pwa/img/health-landing11.svg"/>
                 </div>
                 </div>
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", marginTop:"10%", textAlign:"center"}}>
            <label style={{color:"#E9373E", fontSize:30}}>Importance of Health Insurance Plans in India</label>     
            <label style={{fontSize:18, fontWeight:"lighter"}}>With rising healthcare expenses, access to high-quality medical treatment can drain your finances.
             Thus, to protect yourself and your loved ones financially against any critical illness every citizen 
             must get insured under a reliable health insurance plan. Here are the top reasons why you should get 
             coverage as early as possible:</label>
            <div className='health-div2' style={{width:"100%", display:"flex", justifyContent:"space-between", marginTop:"5%", marginBottom:"5%"}}>
                <div className='health-div2-sub' style={{boxShadow:"0px 0px 5px grey" ,width:"30%", minHeight:"80vh", textAlign:"start", padding:"2%"}}>
                    <img width="15%" src={wallet}/><br/>
                    <b style={{fontSize:15}}>To Protect Your Savings </b>
                    <div style={{textAlign:"justify"}}>
                    <br/><label style={{fontSize:15, fontWeight:"lighter"}}>Though savings are required to meet unexpected future expenses. But, a single hospitalization can drain your life savings in one go. However, you can overcome the situation by getting secured through comprehensive coverage provided by different insurance providers. Most commonly, the companies provide benefits for in-patient hospitalization expenses, pre and post-hospitalization expenses, day- care treatment, ambulance charges, and much more. Besides all, a variety of plans are available in the market. among which an individual can buy as per their specific requirements.</label>
                </div>
                </div>
                
            <div className='health-div2-sub' style={{boxShadow:"0px 0px 5px grey" ,width:"30%", minHeight:"80vh", textAlign:"start", padding:"2%"}}>
                    <img width="15%" src={heart}/><br/>
                    <b style={{fontSize:15}}>To Deal With Medical Inflation</b>
                    <div style={{textAlign:"justify"}}>
                    <br/><label style={{fontSize:15, fontWeight:"lighter"}}>An unexpected critical illness like Cancer, HIV, Heart disease and more can put anyone under stress because of the increased cost of high-quality treatment. Besides treatment expenses, there are many other costs involved like medicines, diagnosis, ambulance charges, consultation fees of a doctor, and much more. Hence, you can save yourself from this drudgery and stressful situation by purchasing a health insurance plan for yourself and your family at the right time.</label>
                </div>
                </div>
                
            <div className='health-div2-sub' style={{boxShadow:"0px 0px 5px grey" ,width:"30%", minHeight:"80vh", textAlign:"start", padding:"2%"}}>
                    <img width="15%" src={plus}/><br/>
                    <b style={{fontSize:15}}>Lifestyle Changes</b>
                    <div style={{textAlign:"justify"}}>
                    <br/><label style={{fontSize:15, fontWeight:"lighter"}}>As we are moving forward with the new lifestyle, critical illnesses like cancer, kidney failure, stroke, and many more critical illnesses are following us. These are unexpected diseases happening to people above 45 years of age due to sedentary lifestyles, stress, pollution, and more. Thus, purchasing a comprehensive health plan will not only keep you updated about your health through regular check-ups but will also provide financial coverage for the treatments.</label>
                </div>
                </div>
                
            </div>
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", marginTop:"10%", textAlign:"center"}}>
        <label style={{color:"#E9373E", fontSize:30}}>Health Insurance Renewal</label>  <br/><br/>  
        <div style={{textAlign:"justify"}}>
            <label style={{fontSize:17, fontWeight:"lighter"}}>Each insurance plan comes with a policy tenure. Thus, your plan is valid for a specific time that varies from one insurance provider to another. Renewing the plan is crucial to carry forward the coverage and make necessary changes and add-ons to your health insurance policy. Every insurance provider offers a renewal period under which the policyholder can renew their plan, in case they are not able to make the renewal, the insurance company offers a grace period which also differs from one insurer to another.</label></div>
            <br/><label style={{fontSize:17, fontWeight:"lighter"}}>In case, you miss to renew your plan on time, then it can lapse and you may not be able to avail of health coverage and other benefits such as No Claim Bonus (NCB). Here are some basic steps that you can follow to renew your health insurance policy.</label>
        <div style={{textAlign:"justify"}}><br/>
        <label style={{fontSize:17, fontWeight:"lighter"}}><b>Step 1: Review your plan:</b> Before renewing your policy, it is crucial to review your existing plan to assess the type of coverage and other benefits offered in it. Thus, this way you will be able to make necessary changes at the time of renewal.</label><br/><br/>
        <label style={{fontSize:17, fontWeight:"lighter"}}><b>Step 2: Visit the insurer’s website to renew your policy:</b> It is easy to renew a plan by simply visiting the insurance provider’s website. You can use your policy details such as name, registered mobile number/ email address, policy number, date of birth and more to log in on the website. After logging in you will be able to review your plan and make changes or additions to your existing coverage.</label><br/><br/>
        <label style={{fontSize:17, fontWeight:"lighter"}}><b>Step 3: Make renewal payment:</b>  After implementing the changes to your existing policy, you can proceed to renew your policy by completing the payment. If you have opted for add-on covers it can increase your premium. Moreover, you can choose among the following payment options such as credit/debit card, NEFT, net banking, e-wallets, UPI, and more to complete your payment.</label>
        </div>
        </div><br/><br/>
        <Footer/>
    </div>
  )
}

export default HealthInsurance