import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'

function PetInsurance() {
    useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
        <Header/>
        <div style={{width:"100%", height:"15vh"}}></div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}> Pet Insurance</label>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Pets are no less than family members and without their presence home just doesn't feel complete. Wherever you go, you will always find a pet-lover who pours immense love on their pets. Now, to protect them from risk, there are a number of pet insurance plans available in the market that offer medical as well as financial protection to their furry family members. So, let’s discuss everything about pet insurance in the sections below.</label>
            </div>
            </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", marginTop:"5%", textAlign:"center"}}>
                <label style={{fontSize:40, color:"#E7393E"}}>Benefits of Pet Insurance </label>
                <div style={{textAlign:"justify"}}>
                    <br/>
                    <label style={{fontSize:17, fontWeight:"lighter"}}>Pet Insurance ensures best protection to your dear pets against risks such as illness, injury, and more. Pet insurance saves money by offering plans that require premium payments to protect you from the hefty charges associated with pet treatment. Also, In case your pet bites or injures a third party, then having pet insurance can help you in getting compensation against such losses, since third party liability is also included in pet insurance plans. There are some pet insurance plans that even offer coverage in case your pet gets stolen or lost.</label>
                    <br/><br/>
                    <div style={{ textAlign:"start"}}>
                <b style={{fontSize:17}}>• &nbsp; What is the best pet insurance around?</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>A number of top insurance companies such as Future Generali, Bajaj Allianz General Insurance Company Limited, The New India Assurance Company Limited, Oriental Insurance Company Limited, etc now offer some of the best pet insurance plans in India.</label>
            </div><br/>
                    <div style={{ textAlign:"start"}}>
                <b style={{fontSize:17}}>• &nbsp; How much does pet insurance cost in India?</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>The cost of pet insurance varies depending on the age, size, and breed of your pet as well as the insurer. So, make an informed decision before choosing the right insurance for your pet.</label>
            </div><br/>
                    <div style={{ textAlign:"start"}}>
                <b style={{fontSize:17}}>• &nbsp; Is pet insurance worth having?</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>Yes, it is worth having pet insurance to protect pets from any kind of medical or unexpected emergencies.</label>
            </div><br/>
                    <div style={{ textAlign:"start"}}>
                <b style={{fontSize:17}}>• &nbsp; Is pet insurance available in India?</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>Yes, pet insurance is also available in India as a number of top insurance companies such as Future Generali, Bajaj Allianz General Insurance Company Limited, The New India Assurance Company Limited, etc, now offer such insurance plans.</label>
            </div><br/>
                </div>
        </div>
        <Footer/>
    </div>
  )
}

export default PetInsurance