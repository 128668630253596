import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MutatingDots } from "react-loader-spinner";
import logo from "../logo.jpeg";
import axios from "axios";
import isilogo from '../ISI.png'
import { baseUrl } from "./baseURL";

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [see, setSee] = useState(false);

  const pst = () => {
    const item = {
      email: email,
      password: pass,
    };
    axios.post(baseUrl+"login", item).then((res) => {
      if (res.data.msg === "ok") {
        sessionStorage.setItem("isLogin","yes")
        navigate("/alldetails");
      } else {
        // navigate("/alldetails");
        alert(res.data.msg)
      }
    });
  };

  return (
    <div
      style={{
        heigh: "100vh",
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <div
        className="Llogo"
        style={{
          height: "100vh",
          width: "50%",
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {" "}
        <img alt="" src={isilogo} style={{width:"100%"}}/>
      </div>

      <div className="lbody" style={{ height: "100vh", width: "50%", backgroundColor:"#E7393E" }}>
        <div className="lcontainer">
          <div className="form" style={{ height: "30%" }}>
            <>
              <input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                autoFocus
              />
              <br />
              <div>
              <input
              style={{zIndex:-1}}
                onChange={(e) => setPass(e.target.value)}
                placeholder="Enter your password"
                type= {see===true?"text": "password"}
                autoFocus
              />
              {see===true?
              <i style={{marginLeft:"-20px", position:"relative", zIndex:10, cursor:"pointer"}} onClick={()=>setSee(false)}  class="fa fa-eye"></i>:
              <i style={{marginLeft:"-20px", position:"relative", zIndex:10, cursor:"pointer"}} onClick={()=>setSee(true)}  className="fa fa-eye-slash"></i>}
           </div>

              <button onClick={() => pst()}>Submit</button>
            </>
          </div>
          <div class="drops">
            <div class="drop drop-1"></div>
            <div class="drop drop-2"></div>
            <div class="drop drop-3"></div>
            <div class="drop drop-4"></div>
            <div class="drop drop-5"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
