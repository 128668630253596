import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'

function LifeInsurance() {
  useEffect(()=>{window.scrollTo(0,0)},[])
  return (
  
    <div>
        <Header/>
        <div style={{width:"100%", height:"15vh"}}></div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>WHY DO YOU NEED LIFE INSURANCE?</label>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Understanding what a life insurance goal is and why you require one can help you make the best decision. A goal for your life insurance policy is simply a strategy for your protection. It asssists you in determining the type, quantiy, and timing of the coverage you require and that is why I have always been eager to assiit people with their insurance requirements. Interested in getting a life insurance policy ?</label>
            </div>
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center", marginTop:"5%"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>With Life Insurance</label>
            <div style={{textAlign:"start"}}>
              <label style={{fontSize:17, fontWeight:"lighter"}}>• You die. Family Grieves.</label><br/>
              <label style={{fontSize:17, fontWeight:"lighter"}}>• Family has to bury you and pays off all debt.</label><br/>
              <label style={{fontSize:17, fontWeight:"lighter"}}>• Family can focus on grieving and has nothing to worry about financially.</label>
            </div>
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center", marginTop:"5%"}}>
          <label style={{fontSize:40, color:"#E7393E"}}>Without Life Insurance</label>
          <div style={{textAlign:"start"}}>
          <label style={{fontSize:17, fontWeight:"lighter"}}>• Family must find to bury you.</label><br/>
          <label style={{fontSize:17, fontWeight:"lighter"}}>• Family must find to pay off your debt, mortgages, carnote, etc.</label><br/>
          <label style={{fontSize:17, fontWeight:"lighter"}}>• Family might have to take on more debt to bury you and to pay off debt. (Loans and credit cards)</label><br/>
          <label style={{fontSize:17, fontWeight:"lighter"}}>• Family struggles to make ends meet.</label><br/>
          <label style={{fontSize:17, fontWeight:"lighter"}}>• Family is sad.</label><br/>
          </div>
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center", marginTop:"5%"}}>
          <label style={{fontSize:40, color:"#E7393E"}}>Why do I need it ?</label>
          <div style={{textAlign:"justify"}}>
          <label style={{fontSize:17, fontWeight:"lighter"}}>If you have people in your life who depend on you financially, life insurance is a must. Life insurance gives you peace of mind knowing that, in the event of your death, you won't leave your family without resources.</label>
          </div>
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center", marginTop:"5%", marginBottom:"5%"}}>
          <label style={{fontSize:40, color:"#E7393E"}}>Why Single People Need Life Insurance</label>
          <div style={{textAlign:"start"}}>
          <label style={{fontSize:17, fontWeight:"lighter"}}>• You have debt.</label><br/>
          <label style={{fontSize:17, fontWeight:"lighter"}}>• You have people who depend on you.</label><br/>
          <label style={{fontSize:17, fontWeight:"lighter"}}>• You own a business.</label><br/>
          <label style={{fontSize:17, fontWeight:"lighter"}}>• You want to pay for final expenses.</label><br/>
          <label style={{fontSize:17, fontWeight:"lighter"}}>• You want to grow your wealth.</label><br/>
          <label style={{fontSize:17, fontWeight:"lighter"}}>• You want to lock in coverage while yor're young and healthy.</label><br/>
          <label style={{fontSize:17, fontWeight:"lighter"}}>• You want to leave a legacy.</label><br/>
            
          </div>
        </div>
        <Footer/>
    </div>
  )
}

export default LifeInsurance