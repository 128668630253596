import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { baseUrl } from './baseURL'

function Quries() {
    useEffect(()=>{getData()},[])
    useEffect(()=>{
      const isLogin = sessionStorage.getItem("isLogin")
      if(!isLogin){
        navigate('/login')
      }
    },[])
    const navigate = useNavigate()
    const [list, setList] = useState([])

    const getData=()=>{
        axios.get(baseUrl+"contact").then((res)=>setList(res.data.data))
    }

    const deleteData=(x)=>{
      axios.delete(baseUrl+"contact/"+x).then(()=>getData())
    }
  return (
    <div
    style={{
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    }}
  >
    <Sidebar />
    <div
        style={{
          minWidth: "80%",
          // backgroundColor: "rgba(231, 57, 62, 0.4)",
          paddingRight:"0.5%"
        }}
      >
        <table
          style={{ width: "100%", backgroundColor: "white", color: "#05386B" }}
          class="table text-nowrap"
        >
          <thead style={{ backgroundColor: "#E7393E", color: "whitesmoke" }}>
            <tr>
              <th scope="col">S No.</th>
              <th scope="col">Full Name</th>
              <th scope="col">Email</th>
              <th scope="col">Number</th>
              <th scope="col">City</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {list.map((i, n) => (
              <tr
                style={{
                  fontWeight: "bold",
                  backgroundColor: n % 2 === 0 ? null : "rgba(255,0,0,0.3)",
                }}
              >
              <td>{n+1}</td>
              <td>{i.name}</td>
              <td>{i.email}</td>
              <td>{i.number}</td>
              <td>{i.city}</td>
              <td><i onClick={()=>deleteData(i._id)} className='fa fa-trash'></i></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Quries