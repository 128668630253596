import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'

function Investment() {
  useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
        <Header/>
        <div style={{width:"100%", height:"15vh"}}></div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>Investment Plans</label>
            <div style={{textAlign:"justify"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>An investment plan provides the opportunity for wealth creation by investing over a time period and getting guaranteed returns. Investment plans are also a good choice to systematically plan for the future regarding financial goals. In simple words, an investment plan is all about multiplying the investor's hard-earned money in the most efficient way possible.</label>
            </div>
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", marginTop:"5%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>Types of Investment Plans</label>
                <div style={{textAlign:"justify"}}>
                    <br/><br/>
                        <label style={{fontSize:17, fontWeight:"lighter"}}>
                        <b>Unit linked insurance Plans : </b>
                        ULIP is a type of life insurance plan that offers the benefit of both insurance cover and investment. A part of the premium paid by the policyholder goes towards insurance cover and another part is invested in various types of funds opted for by the policyholder. The valuation of the investment can be checked anytime by checking the net asset value of the fund. On death of the insured, the nominee gets the sum assured, whereas, on survival, the policyholder gets the maturity benefit.
                        </label><br/><br/>
                        <label style={{fontSize:17, fontWeight:"lighter"}}>
                        <b> Public provident fund : </b>
                         PPF is one of the popular investment options in India that offers an individual the opportunity to save as well as invest. One can invest a minimum of Rs. 500- Rs. 1.5 lakh under this investment option. Investment under this investment option can be done on a lump sum basis or on a monthly basis.
                        </label><br/><br/>
                        <label style={{fontSize:17, fontWeight:"lighter"}}>
                        <b> National Savings Certificate : </b>
                         Known as NSC, this is another popular government offered investment option. Investment under this scheme can be purchased from any post office in India. The minimum investment amount is Rs.100 and the rate of interest offered varies depending on the government. You can also enjoy tax benefits under section. 80C of the Income Tax Act, 1961 on the principal amount.
                        </label><br/><br/>
                        <label style={{fontSize:17, fontWeight:"lighter"}}>
                        <b> Mutual Funds : </b>
                         A mutual fund is another investment option that offers you an option to invest in various funds as per your goals, risk appetite. These are managed by professional asset management and the units purchased and sold are based on the net asset value.
                        </label><br/><br/>
                        <label style={{fontSize:17, fontWeight:"lighter"}}>
                        <b> Tax saving mutual funds:  </b>
                         Known as equity linked savings scheme, this mutual fund offers tax benefits upto Rs.1.5 lakh. The minimum lock in period of this investment option is 3 years.
                        </label><br/><br/>
                        <label style={{fontSize:17, fontWeight:"lighter"}}>
                        <b> National pension scheme:  </b>
                          This is an investment scheme specially designed for government employees. From the year 2009, the scheme has been extended to non-government employees as well.
                        </label><br/><br/>
                        <label style={{fontSize:17, fontWeight:"lighter"}}>
                        <b> Fixed deposits:  </b>
                         Known as term deposit, this is a traditional investment option under which the rate of interest remains the same. The investor gets an idea of the returns he can expect from this investment. The rate of interest varies from bank to bank.
                        </label><br/><br/>
                        <label style={{fontSize:17, fontWeight:"lighter"}}>
                        <b> Senior citizen savings scheme:  </b>
                          This is an investment tool specifically designed for people aged above 60 years. The rate of return under this scheme is defined by the Government. The deposit made into this account cannot exceed Rs.15 lakh.
                        </label><br/><br/>
                        <label style={{fontSize:17, fontWeight:"lighter"}}>
                        <b> Public provident fund:  </b>
                        This is a traditional investment option that helps you build wealth at low risk. The scheme is a well known retirement tool and is backed by the Government of India. The scheme offers tax benefits up to Rs.1.5 lakh and allows partial withdrawals. The maximum tenure of the scheme is 15 years and minimum investment amount is Rs. 500.
                        </label><br/><br/>
                        <label style={{fontSize:17, fontWeight:"lighter"}}>
                        <b> Capital guarantee plan:  </b>
                        this is similar to ULIP plan under which a part of the investment is invested in insurance and another is invested in debt for capital protection and remaining towards equity. The premium paying term of this plan is 15 years and tenure is 5 years. With this type of plan, the investor can be rest assured towards their investment as the major focus of this scheme is to safeguard investors money from any losses.
                        </label>
                </div>
            
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", marginTop:"5%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}>How to choose the best investment plan?</label>
        <div style={{textAlign:"start"}}>
            <br/>
            <label style={{fontSize:17, fontWeight:"lighter"}}>Choosing an investment plan is easy if you are well aware of the steps to choose one.</label>
            <div style={{paddingLeft:"3%"}}>
                <br/>
                    <label style={{fontSize:16, fontWeight:"lighter"}}>• &nbsp; Check on your financial requirements and objectives</label>
                    <label style={{fontSize:16, fontWeight:"lighter"}}>• &nbsp; Know about the tenure which you can invest for in order to fulfill your investment objective</label>
                    <label style={{fontSize:16, fontWeight:"lighter"}}>• &nbsp; Create an investment that offers you a mix of investment and insurance coverage and is in line with your investment objective</label>
                    <label style={{fontSize:16, fontWeight:"lighter"}}>• &nbsp; Diversify your investments and do not invest only in a single financial product</label>
                    <label style={{fontSize:16, fontWeight:"lighter"}}>• &nbsp; Read on the terms and conditions of the financial planning, understanding the fees and other conditions</label>
                    <label style={{fontSize:16, fontWeight:"lighter"}}>• &nbsp; Review your investment on a regular basis to ensure that your financial objectives will be fulfilled within the time you have invested for.</label>
            </div>
        </div>
        </div>
        <Footer/>
    </div>
  )
}

export default Investment