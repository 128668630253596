import React from 'react'
import footer from './footer.css'
import { useNavigate } from 'react-router-dom'
import isiwhite from '../ISI WHITE.png'

function Footer() {
    const navigate = useNavigate()
    const instagram = ()=>{ window.open("https://instagram.com/insurance_specialist.india?igshid=ZGUzMzM3NWJiOQ==")}
    const facebook = ()=>{ window.open("https://www.facebook.com/amiteshkale8?mibextid=ZbWKwL")}
    const telegram = ()=>{ window.open("https://www.facebook.com/amiteshkale8?mibextid=ZbWKwL")}
    return (
    <div>
        <div className='footer-uper' style={{width:"100%", height:"50vh", backgroundColor:"#333333", display:"flex", flexDirection:"column",
        justifyContent:"center", alignItems:"center", position:"relative", zIndex:2}}>
            <b style={{color:"white", fontSize:30, letterSpacing:5}}>EXPLORE OUR SERVICES</b>
            <br/>
            <button className='btn-cont' onClick={()=>navigate("/contact")}>Connect with us now</button>
        </div>
        <div className='footer'>
            <div className='foot-div' style={{width:"20%", height:"40%"}}>
                <label style={{fontSize:20, color:"#E7393E"}}>CONTACT US</label>
                <br/>
                <br/>
                <div style={{fontSize:15, width:"100%", display:"flex", textAlign:"start", alignItems:"center", gap:"10%"}}>
                <i class="fa fa-phone-square" aria-hidden="true"></i> 
                <a style={{color:"white"}} href='tel:9039937352'>+91 9039937352</a>
                </div>
                <br/>
                <div style={{fontSize:15, width:"100%", display:"flex",  alignItems:"center", textAlign:"start", gap:"10%"}}>
                <i class="fa fa-envelope " aria-hidden="true"></i> 
                <a style={{color:"white"}} href='mailto:amiteshkale8@gmail.com'> amiteshkale8@gmail.com</a>
                </div>
                <br/>
                <div style={{fontSize:15, width:"100%", display:"flex",  alignItems:"center", gap:"10%"}}>
                <i class="fa fa-map-marker" style={{fontSize:22}} aria-hidden="true"></i>
                <div style={{display:"flex", flexDirection:"column", textAlign:"start" }}>
                <a> INDORE (Madhya Pradesh)</a>
                <a> and all India service available</a>
                </div>
                </div>
            </div>
            <div className='foot-div' style={{width:"10%", height:"40%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                <label style={{fontSize:20, color:"#E7393E"}}>Quick Links</label>
                <a className='anch-scale' style={{cursor:"pointer"}}>Career</a>
                <a className='anch-scale' style={{cursor:"pointer"}} onClick={()=>navigate("/aboutus")}>About Us</a>
                <a className='anch-scale' style={{cursor:"pointer"}}>Our Team</a>
                <a className='anch-scale' style={{cursor:"pointer"}} onClick={()=>navigate("/contact")}>Contact Us</a>
                <a className='anch-scale' style={{cursor:"pointer"}}>Testimonials</a> 
            </div>
            <div className='foot-div' style={{width:"10%", height:"60%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                <label style={{fontSize:20, color:"#E7393E" }}>INSURANCE</label>
                <a className='anch-scale' style={{cursor:"pointer"}} onClick={()=>navigate("/healthinsurance")}>Health Insurance</a>
                <a className='anch-scale' style={{cursor:"pointer"}} onClick={()=>navigate("/lifehinsurance")}>Life Insurance</a>
                <a className='anch-scale' style={{cursor:"pointer"}} onClick={()=>navigate("/terminsurance")}>Term Insurance</a>
                <a className='anch-scale' style={{cursor:"pointer"}} onClick={()=>navigate("/bikeinsurance")}>Bike Insurance</a>
                <a className='anch-scale' style={{cursor:"pointer"}} onClick={()=>navigate("/carinsurance")}>Car Insurance</a>
                <a className='anch-scale' style={{cursor:"pointer"}} onClick={()=>navigate("/travelinsurance")}>Travel Insurance</a>
                <a className='anch-scale' style={{cursor:"pointer"}} onClick={()=>navigate("/petinsurance")}>Pet Insurance</a>
                {/* <a style={{cursor:"pointer"}} onClick={()=>navigate("/fireinsurance")}>Fire Insurance</a> */}
            </div>
            <div className='foot-div' style={{width:"25%", height:"40%"}}>
                <img style={{width:"100%"}} src={isiwhite}/>
                <div style={{display:"flex", gap:"5%",fontSize:30, color:"white", paddingLeft:"5%"}}>
                <i onClick={instagram} class="fa fa-instagram" aria-hidden="true"></i>
                <i onClick={facebook} class="fa fa-facebook-square" aria-hidden="true"></i>
                <i onClick={telegram} class="fa fa-telegram" aria-hidden="true"></i>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer