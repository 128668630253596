import React, { useEffect, useState } from "react";
import axios from "axios";
import City from "./city.json";
import State from "./state.json";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "./baseURL";
import backbtn from "../backbtn.png";
import storage from "./Firebase";
import { PropagateLoader } from "react-spinners";
function Register() {
  useEffect(() => {
    const isLogin = sessionStorage.getItem("isLogin");
    if (!isLogin) {
      navigate("/login");
    }
  }, []);
  const [postDetails, setPostDetails] = useState({
    panNumber: "",
    members: [],
    insurance: [],
  });
  const [insuranceDetails, setInsuranceDetails] = useState([]);
  const [insuranceObj, setInsuranceObj] = useState({});
  const [screen, setScreen] = useState(-1);
  const [city] = useState(City);
  const [state] = useState(State);
  const [icon, setIcon] = useState(false);
  const [icon1, setIcon1] = useState(false);
  const [icon2, setIcon2] = useState(false);
  const [icon3, setIcon3] = useState(false);
  const [image, setImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [validator, setValidator] = useState(false);

  const weightData = new Array(130).fill().map((i, n) => n + 1);
  const postData = () => {
    if ((postDetails?.panNumber !== "") & !loader) {
      setLoader(true);
      if (postDetails._id) {
        axios
          .put(baseUrl + "details/" + postDetails._id, {
            ...postDetails,
            insurance: insuranceDetails,
          })
          .then(() => navigate("/"));
      } else {
        axios
          .post(baseUrl + "details", {
            ...postDetails,
            insurance: insuranceDetails,
          })
          .then(() => navigate("/"))
          .catch(() => {
            setLoader(false);
            alert("pan already registered");
          });
      }
    } else {
      setValidator(true);
    }
    localStorage.setItem("postitems", JSON.stringify(postDetails));
  };
  const postData1 = () => {
    if ((postDetails?.panNumber !== "") & !loader) {
      setLoader(true);
      if (postDetails._id) {
        axios
          .put(baseUrl + "details/" + postDetails._id, {
            ...postDetails,
            insurance: insuranceDetails,
          })
          .then((res) => {
            setLoader(false);
            localStorage.setItem("postitems", JSON.stringify(res.data.data));
            setPostDetails(res.data.data);
          })
          .catch(() => {
            setLoader(false);
            alert("pan already registered");
          });
      } else {
        axios
          .post(baseUrl + "details", {
            ...postDetails,
            insurance: insuranceDetails,
          })
          .then((res) => {
            setLoader(false);
            localStorage.setItem("postitems", JSON.stringify(res.data.data));
            setPostDetails(res.data.data);
          })
          .catch(() => {
            setLoader(false);
            alert("pan already registered");
          });
      }
    } else {
      setValidator(true);
    }
  };

  const navigate = useNavigate();
  const uploadImage1 = (x, y, z) => {
    if (x !== "") {
      const uploadTask = storage.ref(`images/${x.name}`).put(x);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          if (y === "a") {
            setProgress(
              Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            );
          } else {
            setProgress1(
              Math.ceil((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            );
          }
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(x.name)
            .getDownloadURL()
            .then((url) => {
              setProgress(0);
              setProgress1(0);
              if (z === 1) {
                if (y === "a") {
                  setPostDetails({
                    ...postDetails,
                    adharImage: url,
                  });
                } else {
                  setPostDetails({
                    ...postDetails,
                    panImage: url,
                  });
                }
              } else {
                if (y === "a") {
                  setPostDetails({
                    ...postDetails,
                    members: postDetails.members.map((j, n) =>
                      n === screen ? { ...j, adharImage: url } : j
                    ),
                  });
                } else {
                  setPostDetails({
                    ...postDetails,
                    members: postDetails.members.map((j, n) =>
                      n === screen ? { ...j, panImage: url } : j
                    ),
                  });
                }
              }
            });
        }
      );
    } else {
      alert("no image selected");
    }
  };
  const [progress, setProgress] = useState(0);
  const [progress1, setProgress1] = useState(0);
  const [isModal, setIsModal] = useState(false);
  return (
    <>
      {loader && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100vh",
            zIndex: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PropagateLoader color="#E7393E" size={22} />
        </div>
      )}
      {isModal && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100vh",
            zIndex: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="relation-div"
            style={{
              width: "30%",
              height: "25vh",
              borderRadius: 10,
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <label
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer",
              }}
              onClick={() => setIsModal(false)}
            >
              ❌
            </label>
            <h3 style={{ color: "#E7393E" }}>Select Relation With Member</h3>
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: "5%",
                justifyContent: "center",
              }}
            >
              <button
                className="btn btn-danger"
                onClick={() => {
                  setIsModal(false);
                  setPostDetails({
                    ...postDetails,
                    members: [...postDetails?.members, { relation: "spouse" }],
                  });
                }}
              >
                Spouse
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  setIsModal(false);
                  setPostDetails({
                    ...postDetails,
                    members: [...postDetails?.members, { relation: "son" }],
                  });
                }}
              >
                Son
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  setIsModal(false);
                  setPostDetails({
                    ...postDetails,
                    members: [
                      ...postDetails?.members,
                      { relation: "daughter" },
                    ],
                  });
                }}
              >
                Daughter
              </button>
            </div>
          </div>
        </div>
      )}
      {image !== "" && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100vh",
            zIndex: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              color: "white",
              position: "absolute",
              right: 30,
              top: 10,
              cursor: "pointer",
            }}
            onClick={() => setImage("")}
          >
            X
          </h1>
          <img src={image} style={{ width: "60%", height: "auto" }} />
        </div>
      )}
      <div>
        {/* <img
        className="backbtn"
        onClick={() => navigate("/alldetails")}
        style={{ width: "3%", position: "absolute", right: 30, top: 30  }}
        src={backbtn}
      /> */}
        <div style={{ display: "flex", backgroundColor: "white" }}>
          {/* <Sidebar /> */}
          <div
            style={{
              width: "100%",
              minHeight: "100vh",
              paddingLeft: "2%",
            }}
          >
            <div>
              <h1 style={{ color: "#E7393E", fontWeight: "bold" }}>
                Member Details
              </h1>
              <label
                onClick={() => setIsModal(true)}
                style={{ color: "#E7393E", cursor: "pointer" }}
              >
                + Add Member
              </label>
            </div>
            <br />
            <div
              style={{
                width: 108 + 108 * postDetails?.members?.length,
                height: "6vh",
                backgroundColor: "rgba(231, 57, 62, 0.5)",
                display: "flex",
                alignItems: "center",
                borderRadius: 30,
                padding: 10,
              }}
            >
              <button
                onClick={() => setScreen(-1)}
                style={{
                  margin: 4,
                  width: 100,
                  height: 30,
                  borderRadius: 30,
                  border: "none",
                  color: "white",
                  backgroundColor: screen === -1 ? "#E7393E" : "transparent",
                }}
              >
                Head
              </button>
              {postDetails?.members.map((i, n) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      margin: 4,
                      alignItems: "center",
                      width: 100,
                      height: 30,
                      borderRadius: 30,
                      border: "none",
                      color: "white",
                      backgroundColor: screen === n ? "#E7393E" : "transparent",
                      paddingRight: 10,
                    }}
                  >
                    <button
                      onClick={() => {
                        setScreen(-2);
                        setTimeout(() => {
                          setScreen(n);
                        }, 100);
                      }}
                      style={{
                        margin: 4,
                        width: 100,
                        height: 30,
                        borderRadius: 30,
                        border: "none",
                        color: "white",
                        backgroundColor:
                          screen === n ? "#E7393E" : "transparent",
                      }}
                    >
                      {i.relation}
                    </button>
                    <i
                      className="fa fa-times"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setPostDetails({
                          ...postDetails,
                          members: postDetails.members.filter(
                            (x, y) => y !== n
                          ),
                        })
                      }
                    ></i>
                  </div>
                </>
              ))}
            </div>
            <br />
            <br />
            {screen === -1 ? (
              <div className="regis-inputs" style={{ gap: "1%" }}>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <select
                    value={postDetails?.title}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        title: e.target.value,
                      })
                    }
                  >
                    <option selected disabled></option>
                    <option>Mr</option>
                    <option>Mrs</option>
                    <option>Miss</option>
                    <option>Master</option>
                  </select>
                  <span>Title</span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>First Name</span>
                  <input
                    // placeholder="First name"
                    value={postDetails?.firstName}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        firstName: e.target.value,
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    // placeholder="Middle name"
                    value={postDetails?.middleName}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        middleName: e.target.value,
                      })
                    }
                  />
                  <span>Middle Name</span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    // placeholder="Last name"
                    value={postDetails?.lastName}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        lastName: e.target.value,
                      })
                    }
                  />
                  <span>Last Name</span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Gender</span>
                  <select
                    value={postDetails?.gender}
                    placeholder="Gender"
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        gender: e.target.value,
                      })
                    }
                  >
                    <option selected disabled></option>

                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    value={postDetails?.dob}
                    type="date"
                    placeholder="Date of birth"
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        dob: e.target.value,
                      })
                    }
                  />
                  <span>DOB</span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Height</span>
                  <select
                    value={postDetails?.height}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        height: e.target.value,
                      })
                    }
                  >
                    <option selected disabled></option>

                    <option>4′0″</option>
                    <option>4′1″</option>
                    <option>4′2″</option>
                    <option>4′3″</option>
                    <option>4′4″</option>
                    <option>4′5″</option>
                    <option>4′6″</option>
                    <option>4′7″</option>
                    <option>4′8″</option>
                    <option>4′9″</option>
                    <option>4′10″</option>
                    <option>4′11″</option>
                    <option>5′0″</option>
                    <option>5′1″</option>
                    <option>5′2″</option>
                    <option>5′3″</option>
                    <option>5′4″</option>
                    <option>5′5″</option>
                    <option>5′6″</option>
                    <option>5′7″</option>
                    <option>5′8″</option>
                    <option>5′9″</option>
                    <option>5′10″</option>
                    <option>5′11″</option>
                    <option>6′1″</option>
                    <option>6′2″</option>
                    <option>6′3″</option>
                    <option>6′4″</option>
                    <option>6′5″</option>
                    <option>6′6″</option>
                    <option>6′7″</option>
                    <option>6′8″</option>
                    <option>6′9″</option>
                    <option>6′10″</option>
                    <option>6′11″</option>
                    <option>7′0″</option>
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Weight</span>
                  <select
                    value={postDetails?.weight}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        weight: e.target.value,
                      })
                    }
                  >
                    <option selected disabled></option>
                    {weightData.map((i) => (
                      <option>{i} kg</option>
                    ))}
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    value={postDetails?.country}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        country: e.target.value,
                      })
                    }
                  />
                  <span>Country</span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    value={postDetails?.aadharNumber}
                    onChange={(e) => {
                      const num = e.target.value;
                      if (num.length < 15) {
                        if ((num.length > 3) & (num.length < 5)) {
                          setPostDetails({
                            ...postDetails,
                            aadharNumber: num + "-",
                          });
                        } else if ((num.length > 8) & (num.length < 10)) {
                          setPostDetails({
                            ...postDetails,
                            aadharNumber: num + "-",
                          });
                        } else {
                          setPostDetails({
                            ...postDetails,
                            aadharNumber: num,
                          });
                        }
                      }
                    }}
                  />
                  <span>Aadhar Card number</span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    style={{
                      border:
                        validator === true
                          ? "1px solid red"
                          : "1px solid lightgrey",
                    }}
                    value={postDetails?.panNumber}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        panNumber: e.target.value,
                      })
                    }
                  />
                  <span style={{ color: validator === true ? "red" : null }}>
                    Pan Card Number
                  </span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Marital Status</span>
                  <select
                    value={postDetails?.maritalStatus}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        maritalStatus: e.target.value,
                      })
                    }
                  >
                    <option selected disabled></option>
                    <option>Married</option>
                    <option>Unmarried</option>
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    value={postDetails?.education}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        education: e.target.value,
                      })
                    }
                  />
                  <span>Education Qualification</span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    value={postDetails?.monthlyIncome}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        monthlyIncome: e.target.value,
                      })
                    }
                  />
                  <span>Gross Monthly income</span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    value={postDetails?.occupation}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        occupation: e.target.value,
                      })
                    }
                  />
                  <span>Occupation</span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>State</span>
                  <select
                    value={postDetails?.state}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        state: e.target.value,
                      })
                    }
                  >
                    <option selected disabled></option>
                    {state.map((i) => (
                      <option>{i.name}</option>
                    ))}
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>City</span>
                  <select
                    value={postDetails?.city}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        city: e.target.value,
                      })
                    }
                  >
                    <option selected disabled></option>
                    {city
                      .filter((i) => i.state === postDetails.state)
                      .map((i) => (
                        <option>{i.name}</option>
                      ))}
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    value={postDetails?.pincode}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        pincode: e.target.value,
                      })
                    }
                  />
                  <span>Pincode</span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    value={postDetails?.address}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        address: e.target.value,
                      })
                    }
                  />
                  <span>Address</span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    value={postDetails?.email}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        email: e.target.value,
                      })
                    }
                  />
                  <span>Email</span>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    value={postDetails?.number}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        number: e.target.value,
                      })
                    }
                  />
                  <span>Number</span>
                </div>
                <div
                  className="regisdiv"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <span>Aadhar Card</span>
                  <input
                    type="file"
                    className="aadhar-card"
                    onChange={(e) => uploadImage1(e.target.files[0], "a", 1)}
                  />
                  <div
                    style={{
                      height: 3,
                      width: "100%",
                      backgroundColor: "lightgrey",
                    }}
                  >
                    <div
                      style={{
                        height: 3,
                        width: `${progress}%`,
                        backgroundColor: "red",
                      }}
                    ></div>
                  </div>
                  <i
                    onClick={() => {
                      if (postDetails.adharImage) {
                        setImage(postDetails.adharImage);
                      }
                    }}
                    style={{ position: "absolute", bottom: -20, right: 0 }}
                    className="fa fa-eye"
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <span>Pan Card</span>
                  <input
                    type="file"
                    className="aadhar-card"
                    onChange={(e) => uploadImage1(e.target.files[0], "p", 1)}
                  />
                  <div
                    style={{
                      height: 3,
                      width: "100%",
                      backgroundColor: "lightgrey",
                    }}
                  >
                    <div
                      style={{
                        height: 3,
                        width: `${progress1}%`,
                        backgroundColor: "red",
                      }}
                    ></div>
                  </div>
                  <i
                    onClick={() => {
                      if (postDetails.panImage) {
                        setImage(postDetails.panImage);
                      }
                    }}
                    style={{ position: "absolute", bottom: -20, right: 0 }}
                    className="fa fa-eye"
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                ></div>

                <button onClick={() => postData1()}>Save</button>
                <button onClick={() => postData()}>Submit details</button>
              </div>
            ) : null}
            {(screen !== -1) & (screen !== -2) ? (
              <div
                className="regis-inputs"
                style={{
                  width: "100%",
                  minHeight: "30vh",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "1%",
                }}
              >
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Title</span>
                  <select
                    value={postDetails.members[screen]?.title}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, title: e.target.value } : j
                        ),
                      })
                    }
                    // placeholder="Title"
                  >
                    <option selected disabled>
                      {/* --select title-- */}
                    </option>
                    <option>Mr</option>
                    <option>Mrs</option>
                    <option>Miss</option>
                    <option>Master</option>
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>First Name</span>
                  <input
                    // placeholder="First name"
                    value={postDetails.members[screen]?.firstName}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, firstName: e.target.value } : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Middle Name</span>
                  <input
                    // placeholder="Middle name"
                    value={postDetails.members[screen]?.middleName}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen
                            ? { ...j, middleName: e.target.value }
                            : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Last Name</span>
                  <input
                    // placeholder="Last name"
                    value={postDetails.members[screen]?.lastName}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, lastName: e.target.value } : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Gender</span>
                  <select
                    placeholder="Gender"
                    value={postDetails.members[screen]?.gender}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, gender: e.target.value } : j
                        ),
                      })
                    }
                  >
                    {" "}
                    <option selected disabled>
                      {/* --select gender-- */}
                    </option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    type="date"
                    placeholder="Date of birth"
                    value={postDetails.members[screen]?.dob}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, dob: e.target.value } : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Height</span>
                  <select
                    // placeholder="Height"
                    value={postDetails.members[screen]?.height}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, height: e.target.value } : j
                        ),
                      })
                    }
                  >
                    <option selected disabled>
                      {/* --select height-- */}
                    </option>

                    <option>4′0″</option>
                    <option>4′1″</option>
                    <option>4′2″</option>
                    <option>4′3″</option>
                    <option>4′4″</option>
                    <option>4′5″</option>
                    <option>4′6″</option>
                    <option>4′7″</option>
                    <option>4′8″</option>
                    <option>4′9″</option>
                    <option>4′10″</option>
                    <option>4′11″</option>
                    <option>5′0″</option>
                    <option>5′1″</option>
                    <option>5′2″</option>
                    <option>5′3″</option>
                    <option>5′4″</option>
                    <option>5′5″</option>
                    <option>5′6″</option>
                    <option>5′7″</option>
                    <option>5′8″</option>
                    <option>5′9″</option>
                    <option>5′10″</option>
                    <option>5′11″</option>
                    <option>6′1″</option>
                    <option>6′2″</option>
                    <option>6′3″</option>
                    <option>6′4″</option>
                    <option>6′5″</option>
                    <option>6′6″</option>
                    <option>6′7″</option>
                    <option>6′8″</option>
                    <option>6′9″</option>
                    <option>6′10″</option>
                    <option>6′11″</option>
                    <option>7′0″</option>
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Weight</span>
                  <select
                    placeholder="Weight"
                    value={postDetails.members[screen]?.weight}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, weight: e.target.value } : j
                        ),
                      })
                    }
                  >
                    <option selected disabled>
                      {/* --select weight-- */}
                    </option>
                    {weightData.map((i) => (
                      <option>{i} kg</option>
                    ))}
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Country</span>
                  <input
                    // placeholder="Indian"
                    value={postDetails.members[screen]?.country}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, country: e.target.value } : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <input
                    value={postDetails.members[screen]?.aadharNumber}
                    onChange={(e) => {
                      const num = e.target.value;
                      if (num.length < 15) {
                        if ((num.length > 3) & (num.length < 5)) {
                          setPostDetails({
                            ...postDetails,
                            members: postDetails.members.map((j, n) =>
                              n === screen
                                ? { ...j, aadharNumber: num + "-" }
                                : j
                            ),
                          });
                        } else if ((num.length > 8) & (num.length < 10)) {
                          setPostDetails({
                            ...postDetails,
                            members: postDetails.members.map((j, n) =>
                              n === screen
                                ? { ...j, aadharNumber: num + "-" }
                                : j
                            ),
                          });
                        } else {
                          setPostDetails({
                            ...postDetails,
                            members: postDetails.members.map((j, n) =>
                              n === screen ? { ...j, aadharNumber: num } : j
                            ),
                          });
                        }
                      }
                    }}
                  />
                  <span>Aadhar Card number</span>
                </div>

                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Pan Card Number</span>
                  <input
                    // placeholder="Pan card number"
                    value={postDetails.members[screen]?.panNumber}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, panNumber: e.target.value } : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Marital Status</span>
                  <select
                    // placeholder="Marital status"
                    value={postDetails.members[screen]?.maritalStatus}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen
                            ? { ...j, maritalStatus: e.target.value }
                            : j
                        ),
                      })
                    }
                  >
                    {" "}
                    <option selected disabled>
                      {/* --select marital status-- */}
                    </option>
                    <option>Married</option>
                    <option>Unmarried</option>
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Education Qualification</span>
                  <input
                    // placeholder="Education qualification"
                    value={postDetails.members[screen]?.education}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, education: e.target.value } : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Gross Monthly Income</span>
                  <input
                    // placeholder="Gross Monthly Income"
                    value={postDetails.members[screen]?.monthlyIncome}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen
                            ? { ...j, monthlyIncome: e.target.value }
                            : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Occupation</span>
                  <input
                    // placeholder="Occupation"
                    value={postDetails.members[screen]?.occupation}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen
                            ? { ...j, occupation: e.target.value }
                            : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>State</span>
                  <select
                    value={postDetails.members[screen]?.state}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, state: e.target.value } : j
                        ),
                      })
                    }
                  >
                    <option selected disabled></option>
                    {state.map((i) => (
                      <option>{i.name}</option>
                    ))}
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>City</span>
                  <select
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        city: e.target.value,
                      })
                    }
                  >
                    <option selected disabled></option>
                    {city
                      .filter((i) => i.state === postDetails.state)
                      .map((i) => (
                        <option>{i.name}</option>
                      ))}
                  </select>
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Pincode</span>
                  <input
                    // placeholder="Pincode"
                    value={postDetails.members[screen]?.pincode}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, pincode: e.target.value } : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Address</span>
                  <input
                    // placeholder="Address"
                    value={postDetails.members[screen]?.address}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, address: e.target.value } : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Email</span>
                  <input
                    // placeholder="Email"
                    value={postDetails.members[screen]?.email}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, email: e.target.value } : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <span>Number</span>
                  <input
                    // placeholder="Number"
                    value={postDetails.members[screen]?.number}
                    onChange={(e) =>
                      setPostDetails({
                        ...postDetails,
                        members: postDetails.members.map((j, n) =>
                          n === screen ? { ...j, number: e.target.value } : j
                        ),
                      })
                    }
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <span>Aadhar Card</span>
                  <input
                    type="file"
                    className="aadhar-card"
                    onChange={(e) => uploadImage1(e.target.files[0], "a", 2)}
                  />
                  <div
                    style={{
                      height: 3,
                      width: "100%",
                      backgroundColor: "lightgrey",
                    }}
                  >
                    <div
                      style={{
                        height: 3,
                        width: `${progress}%`,
                        backgroundColor: "red",
                      }}
                    ></div>
                  </div>
                  <i
                    onClick={() => {
                      if (postDetails.members[screen]?.adharImage) {
                        setImage(postDetails.members[screen]?.adharImage);
                      }
                    }}
                    style={{ position: "absolute", bottom: -20, right: 0 }}
                    className="fa fa-eye"
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <span>Pan Card</span>
                  <input
                    type="file"
                    className="aadhar-card"
                    onChange={(e) => uploadImage1(e.target.files[0], "p", 2)}
                  />
                  <div
                    style={{
                      height: 3,
                      width: "100%",
                      backgroundColor: "lightgrey",
                    }}
                  >
                    <div
                      style={{
                        height: 3,
                        width: `${progress1}%`,
                        backgroundColor: "red",
                      }}
                    ></div>
                  </div>
                  <i
                    onClick={() => {
                      if (postDetails.members[screen]?.panImage) {
                        setImage(postDetails.members[screen]?.panImage);
                      }
                    }}
                    style={{ position: "absolute", bottom: -20, right: 0 }}
                    className="fa fa-eye"
                  />
                </div>
                <div
                  className="regisdiv"
                  style={{ display: "flex", flexDirection: "column" }}
                ></div>
                <button onClick={() => postData1()}>Save</button>
                <button onClick={() => postData()}>Submit details</button>
              </div>
            ) : null}
            {screen === -2 ? (
              <div
                className="regis-inputs"
                style={{
                  width: "100%",
                  minHeight: "30vh",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "1%",
                }}
              >
                <input
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, title: e.target.value } : j
                      ),
                    })
                  }
                  placeholder="...Title"
                />
                <input
                  placeholder="First Name"
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, firstName: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="...Middle Name"
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, middleName: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="...Last Name"
                  value={postDetails.members[screen]?.lastName}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, lastName: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Gender"
                  value={postDetails.members[screen]?.gender}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, gender: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Date of birth"
                  value={postDetails.members[screen]?.dob}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, dob: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Height"
                  value={postDetails.members[screen]?.height}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, height: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Weight"
                  value={postDetails.members[screen]?.weight}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, weight: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Indian"
                  value={postDetails.members[screen]?.country}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, country: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Aadhar Card NUmber"
                  value={postDetails.members[screen]?.aadharNumber}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen
                          ? { ...j, aadharNumber: e.target.value }
                          : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="PAN Card Number"
                  value={postDetails.members[screen]?.panNumber}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, panNumber: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Marital Status"
                  value={postDetails.members[screen]?.maritalStatus}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen
                          ? { ...j, maritalStatus: e.target.value }
                          : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Education Qualification"
                  value={postDetails.members[screen]?.education}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, education: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Gross Monthly Income"
                  value={postDetails.members[screen]?.monthlyIncome}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen
                          ? { ...j, monthlyIncome: e.target.value }
                          : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Occupation"
                  value={postDetails.members[screen]?.occupation}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, occupation: e.target.value } : j
                      ),
                    })
                  }
                />

                <input
                  placeholder="Renewal date"
                  value={postDetails.members[screen]?.renewalDate}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, renewalDate: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="State"
                  value={postDetails.members[screen]?.state}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, state: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="City"
                  value={postDetails.members[screen]?.city}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, city: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Pincode"
                  value={postDetails.members[screen]?.pincode}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, pincode: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Address"
                  value={postDetails.members[screen]?.address}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, address: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Email"
                  value={postDetails.members[screen]?.email}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, email: e.target.value } : j
                      ),
                    })
                  }
                />
                <input
                  placeholder="Number"
                  value={postDetails.members[screen]?.number}
                  onChange={(e) =>
                    setPostDetails({
                      ...postDetails,
                      members: postDetails.members.map((j, n) =>
                        n === screen ? { ...j, number: e.target.value } : j
                      ),
                    })
                  }
                />

                <button onClick={() => postData()}>Submit details1</button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}
export default Register;
