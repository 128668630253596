import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import amitesh from '../amitesh.jpeg'
import healthimg from '../healthimg.jpeg'
import carimg from '../carimg.jpeg'
import lifeimg from '../lifeimg.jpeg'
import investimg from '../investimg.jpeg'

function AboutUs() { 
  useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
      <Header/>
      <div style={{width:"100%", height:"15vh"}}></div>
        <div className='about-div1' style={{width:"80%", minHeight:"20vh", marginLeft:"10%", display:"flex", marginTop:"5%", marginBottom:"5%"}}>
          <div className='about-div1-sub' style={{width:"50%", height:"60vh", display:"flex", justifyContent:"start", alignItems:"center"}}>
              <div>
                <label style={{letterSpacing:1, fontSize:17}}>Hello</label><br/>
                <label style={{fontSize:50}}>It's Amitesh Kale</label><br/>
                <label style={{fontSize:32, color:"#E7393E"}}>Founder & CEO</label> <label style={{fontSize:17}}>of</label>
                 <br/><label style={{fontSize:25, color:"#E7393E"}}>INSURANCE SPECIALIST INDIA</label>
              </div>
          </div>
          <div className='about-div1-sub2' style={{width:"50%", height:"30vh", display:"flex", justifyContent:"center", position:"relative"}}>
            <img width="70%" style={{position:"absolute", zIndex:2, borderRadius:10}}  src={amitesh}/>
            <div className='red-border-div' style={{position:'absolute', width:"70%", height:"60vh", border:"10px solid #E7393E", borderRadius:10, top:30, left:50, zIndex:1}}></div>
          </div>
        </div>
        <div style={{width:"80%",marginLeft:"10%", height:0.5 ,boxShadow:"1px 0 2px #E7393E"}}></div>
      <div className='about-div2' style={{width:"80%", minHeight:"20vh", marginLeft:"10%", display:"flex"}}>
        <div className='about-div2-sub' style={{width:"40%", height:"40vh", display:"flex", justifyContent:"center", flexDirection:"column"}}>
          <label style={{fontSize:40}}>About Me</label>
          <div className='line-ani' style={{ height:5, backgroundColor:"#E7393E"}}></div>
        </div>
        <div className='about-div2-sub' style={{width:"60%", height:"40vh", textAlign:"justify", display:"flex", alignItems:"center"}}>
          <label style={{fontSize:17, fontWeight:"lighter"}}>I am an insurance broker,I am certified under INSURANCE REGULATORY AND DEVELOPMENT AUTHORITY OF INDIA(IRDA).
          And this is my startup from which I am trying provide best quality service.</label>
        </div>
      </div>
      <div className='about-div1' style={{width:"80%", minHeight:"20vh", marginLeft:"10%", display:"flex"}}>
        <div className='about-div2-sub' style={{width:"60%", height:"40vh", textAlign:"justify", display:"flex", alignItems:"center"}}>
          <label style={{fontSize:17, fontWeight:"lighter"}}> My company a best quality service provider .
          My company provides you all types of insurance and investment plans, at one place.          
          and I have only one motive to open this company that I can do all service provider at one place.</label>
        </div>
        <div className='about-div2-sub' style={{width:"40%", height:"40vh", display:"flex", justifyContent:"center", alignItems:"end", flexDirection:"column"}}>
          <label style={{fontSize:40}}>About us</label>
          <div className='line-ani' style={{ height:5, backgroundColor:"#E7393E"}}></div>
        </div>
      </div>
      <div className='about-service' style={{width:"100%", minHeight:"60vh",  backgroundColor:"whitesmoke", display:"flex", flexDirection:"column", textAlign:"center", paddingBottom:"5%", paddingTop:"5%" , marginTop:"5%"}}>
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginBottom:"2%"}}>
        <label style={{fontSize:40,}}>Services</label>
        <div className='line-ani1' style={{width:"10%", height:5, backgroundColor:"#E7393E"}}></div>
        </div>
        <label style={{fontSize:20, fontWeight:"lighter"}}>In tough times, it's harder and even <br/> more important to keep our customers <br/> happy</label>
        <div className='about-service-div' style={{width:"60%",  marginLeft:"20%", display:"flex", flexWrap:"wrap", gap:"10%", justifyContent:"space-evenly", marginTop:"5%" }}>
          <img style={{width:"35%", borderRadius:10, boxShadow:"2px 2px 15px #E7393E",}} src={carimg}/>
          <img style={{width:"35%", borderRadius:10, boxShadow:"2px 2px 15px #E7393E",}} src={healthimg}/>
          <img style={{width:"35%", borderRadius:10, boxShadow:"2px 2px 15px #E7393E", marginTop:"5%"}} src={investimg}/>
          <img style={{width:"35%", borderRadius:10, boxShadow:"2px 2px 15px #E7393E", marginTop:"5%"}} src={lifeimg}/>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default AboutUs