import React, { useEffect, useState } from "react";
import logo from "../logo.jpeg";
import pic from "../pic.avif";
import home from './home.css'
import { useNavigate } from "react-router-dom";

function Home() {
  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  });
  const navigate = useNavigate()
  const [insDiv, setInsDiv] = useState(false);
  const [supDiv, setSupDiv] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY)
      if (window.scrollY >= 40) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
  };

  const [showdiv, setShowdiv] = useState(1);
  const [showcont, setShowcont] = useState(0);
  return (
    <div>
      {/* HEADER */}
      <div 
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          boxShadow: navbar ? "0px 1px 10px lightgrey" : null,
          backgroundColor: navbar ? "white" : "transparent",
          marginTop: navbar ? 0 : -100,
          position: "fixed",
          top: 0,
          transition: navbar?"0.5s":"0s",
          zIndex: 1,
        }}
      >
        <div style={{ width: "30%" }}>
          <img style={{ width: 80, height: 80 }} src={logo} />
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            justifyContent: "space-evenly",
            fontWeight: "bold",
            position: "relative",
          }}
        >
          <div
            className="head-list"
            onMouseOver={() => {
              setInsDiv(true);
              setSupDiv(false);
            }}
          >
            <label>Insurance </label>
            <i class="fa fa-sort-desc" style={{marginTop:"-10%"}} aria-hidden="true"></i>
          </div>

          <div
            onMouseLeave={() => setInsDiv(false)}
            style={{
              width: "70%",
              height: insDiv === true ? 350 : 0,
              overflow: "hidden",
              transition: "0.5s",
              backgroundColor: "white",
              position: "absolute",
              top: 50,
              left: 20,
              display: !navbar?"none":"flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              borderRadius: 10,
              paddingLeft: 20,
              boxShadow: "1px 1px 5px grey",
            }}
          >
            <label>Health Insurance</label>
            <label>Life Insurance</label>
            <label>Car Insurance</label>
            <label>Bike Insurance</label>
            <label>Fire Insurance</label>
          </div>

          <div
            className="head-list"
            onMouseOver={() => {
              setSupDiv(true);
              setInsDiv(false);
            }}
          >
            <label>Support </label>
            <i class="fa fa-sort-desc" style={{marginTop:"-10%"}} aria-hidden="true"></i>
          </div>

          <div
            onMouseLeave={() => setSupDiv(false)}
            style={{
              width: "70%",
              height: supDiv === true ? 150 : 0,
              overflow: "hidden",
              transition: "0.5s",
              backgroundColor: "white",
              position: "absolute",
              top: 50,
              right: 20,
              display: !navbar?"none":"flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              borderRadius: 10,
              paddingLeft: 20,
              boxShadow: "1px 1px 5px grey",
            }}
          >
            <label>Download Policy</label>
            <a href="tel:+91 8889223659"> Call us -8889223659</a>
          </div>
        </div>
      </div>
      <div 
        style={{
          width: "100%",
          display: navbar?"none":"flex",
          justifyContent: "space-around",
          alignItems: "center",
          boxShadow: navbar ? "0px 1px 10px lightgrey" : null,
          backgroundColor: navbar ? "white" : "transparent",
          position: "fixed",
          top: 0, 
          zIndex: 1,
        }}
      >
        <div style={{ width: "30%" }}>
          <img style={{ width: 80, height: 80 }} src={logo} />
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            justifyContent: "space-evenly",
            fontWeight: "bold",
            position: "relative",
          }}
        >
          <div
            className="head-list"
            onMouseOver={() => {
              setInsDiv(true);
              setSupDiv(false);
            }}
          >
            <label>Insurance </label>
            <i class="fa fa-sort-desc" style={{marginTop:"-10%"}} aria-hidden="true"></i>
          </div>

          <div
            onMouseLeave={() => setInsDiv(false)}
            style={{
              width: "70%",
              height: insDiv === true ? 350 : 0,
              overflow: "hidden",
              transition: "0.5s",
              backgroundColor: "white",
              position: "absolute",
              top: 50,
              left: 20,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              borderRadius: 10,
              paddingLeft: 20,
              boxShadow: "1px 1px 5px grey",
            }}
          >
            <label>Health Insurance</label>
            <label>Life Insurance</label>
            <label>Car Insurance</label>
            <label>Bike Insurance</label>
            <label>Fire Insurance</label>
          </div>

          <div
            className="head-list"
            onMouseOver={() => {
              setSupDiv(true);
              setInsDiv(false);
            }}
          >
            <label>Support </label>
            <i class="fa fa-sort-desc" style={{marginTop:"-10%"}} aria-hidden="true"></i>
          </div>

          <div
            onMouseLeave={() => setSupDiv(false)}
            style={{
              width: "70%",
              height: supDiv === true ? 150 : 0,
              overflow: "hidden",
              transition: "0.5s",
              backgroundColor: "white",
              position: "absolute",
              top: 50,
              right: 20,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              borderRadius: 10,
              paddingLeft: 20,
              boxShadow: "1px 1px 5px grey",
            }}
          >
            <label>Download Policy</label>
            <a href="tel:+91 8889223659"> Call us -8889223659</a>
          </div>
        </div>
      </div>
      {/* ----------------------------- */}
      <div style={{ width: "100%", height: "100vh", position: "absolute" }}>
        <div className="redbox"></div>
        <div className="bluebox"></div>
      </div>

      <div
        style={{
          width: "60%",
          height: "40vh",
          marginLeft: "20%",
          display: "flex",marginTop:150,
        }}
      >
        <div
          style={{
            width: "60%",
            height: "100%",
            fontSize: 35,

            display: "flex",
            alignItems: "center",
          }}
        >
          <label style={{ fontWeight: "bolder", fontFamily: "Archivo Black" }}>
            Best Insurance plan se <br />{" "}
            <label style={{ color: "	#4285F4", fontWeight: "900" }}>
              {" "}
              Sukoon ka Bharosa
            </label>
          </label>
        </div>
        <img src={pic} />
      </div>

      {/* Insurance Div */}
      <div
        style={{
          width: "80%",
          height: "20vh",
          backgroundColor: "white",
          marginLeft: "10%",
          borderRadius: 20,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "20%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: 50 }}
            src="https://www.insurancedekho.com/pwa/img/v2_icon_life.svg"
          />
          <label style={{ fontSize: 20, fontWeight: "bold" }}>Life</label>
          <small>Insurance</small>
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: 50 }}
            src="https://www.insurancedekho.com/pwa/img/v2_icon_health.svg"
          />
          <label style={{ fontSize: 20, fontWeight: "bold" }}>Health</label>
          <small>Insurance</small>
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: 50 }}
            src="https://www.insurancedekho.com/pwa/img/v2_icon_car.svg"
          />
          <label style={{ fontSize: 20, fontWeight: "bold" }}>Car</label>
          <small>Insurance</small>
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: 50 }}
            src="https://www.insurancedekho.com/pwa/img/v2_icon_bike.svg"
          />
          <label style={{ fontSize: 20, fontWeight: "bold" }}>Bike</label>
          <small>Insurance</small>
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            style={{ width: 50 }}
            src="https://www.insurancedekho.com/pwa/img/v2_icon_life.svg"
          />
          <label style={{ fontSize: 20, fontWeight: "bold" }}>Fire</label>
          <small>Insurance</small>
        </div>
      </div>

      <div
        style={{
          width: "20%",
          textAlign: "center",
          fontSize: 20,
          marginTop: "5%",
          marginLeft: "40%",
        }}
      >
        <i class="fa fa-download" aria-hidden="true"></i>
        <b style={{ marginLeft: "1%", cursor: "pointer" }}>Download Policy</b>
      </div>

      <div
        style={{
          width: "80%",
          height: "40vh",
          backgroundColor: "rgba(255,0,0,0.05)",
          marginTop: "10%",
          marginLeft: "10%",
          borderRadius: 10,
          position: "relative",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div style={{ width: "40%" }}>
          <img
            style={{ position: "absolute", bottom: 0, left: 100 }}
            src="https://www.insurancedekho.com/pwa/img/personalized_insurance.png"
          />
        </div>
        <div
          style={{
            width: "60%",
            display: "flex",
            alignItems: "center",
            fontSize: 40,
          }}
        >
          <label>
            Get personalized health <br /> insurance advice at your home
          </label>
        </div>
      </div>

      <div style={{ width: "100%", textAlign: "center", marginTop: "5%" }}>
        <b style={{ fontSize: 40 }}>Benefits of Insurance</b>
        <br />
        <label style={{ fontSize: 20, color: "grey" }}>
          Understand your insurance policy options. Identify the best value.
          Enjoy peace of mind.
        </label>
      </div>
      <div
        style={{
          width: "80%",
          minHeight: "50vh",
          marginLeft: "10%",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5%",
        }}
      >
        <div style={{ width: "30%", textAlign: "justify" }}>
          <img
            style={{ width: "60%" }}
            src="https://static.insurancedekho.com/pwa/img/time.svg"
          />
          <br />
          <label style={{ fontSize: 20, fontWeight: "bold" }}>
            5 Minutes Policy Issuance*
          </label>
          <br />
          <br />
          <label>
            Say no to spending hours and days in queues doing the paperwork for
            your insurance policy. Get your insurance issued instantly with
            InsuranceDekho. The entire process from selecting the best insurance
            policy to getting it issued takes just 5 minutes on InsuranceDekho.
          </label>
        </div>
        <div style={{ width: "30%", textAlign: "justify" }}>
          <img
            style={{ width: "60%" }}
            src="https://static.insurancedekho.com/pwa/img/policy-sold.svg"
          />
          <br />
          <label style={{ fontSize: 20, fontWeight: "bold" }}>
            Over 45 Lac Happy Customers
          </label>
          <br />
          <br />
          <label>
            InsuranceDekho is becoming a household name in India. Till now we
            have been successful in providing a delightful experience to more
            than 45 lac customers with the help of our transparent and quick
            process, a dedicated support team along with the availability of
            numerous insurers.
          </label>
        </div>
        <div style={{ width: "30%", textAlign: "justify" }}>
          <img
            style={{ width: "60%" }}
            src="https://static.insurancedekho.com/pwa/img/support.svg"
          />
          <br />
          <label style={{ fontSize: 20, fontWeight: "bold" }}>
            Dedicated Support Team
          </label>
          <br />
          <br />
          <label>
            Our dedicated support team is available for your assistance all the
            7 days. Feel free to reach out to us in case of any confusion - be
            it related to the purchase of an insurance policy or assistance
            during the settlement of a claim, our team of experts is at your
            service all days.
          </label>
        </div>
      </div>

      {/* FAQS */}

    
      <div
        style={{
          width: "70%",
          minHeight: "50vh",
          marginLeft: "15%",
          marginTop: "5%",
        }}
      >
        <div style={{ width: "100%", textAlign: "center" }}>
          <b style={{ fontSize: 35 }}>
            Frequently Asked Questions About Insurance
          </b>
        </div>
        <div
          style={{
            width: "100%",
            height: "10vh",
            marginTop: "3%",
            color: "grey",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <b onClick={() => setShowdiv(1)}>Health</b>
          <b onClick={() => setShowdiv(2)}>Life</b>
          <b onClick={() => setShowdiv(3)}>Car</b>
          <b onClick={() => setShowdiv(4)}>Bike</b>
          <b onClick={() => setShowdiv(5)}>Fire</b>
        </div>
        {showdiv === 1 ? (
          <>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  1
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>What is a health insurance plan?</label>
                  <div>
                    {showcont === 1 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(1)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 1 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Health insurance policy is an agreement whereby an insurance
                    company agrees to undertake a guarantee to compensate the
                    insured for medical expenses in case of a medical emergency.
                    A health insurance policy protects the insured for several
                    surgical expenses, critical illnesses, and daycare expenses,
                    for a policy term, for up to the sum insured limit.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  2
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>
                    Can I purchase more than 1 health insurance plan? How are
                    the claims settled in this case?
                  </label>
                  <div>
                    {showcont === 2 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(2)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 2 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Yes. You can be covered under more than one health insurance
                    plan. In this case, the claims are settled as per the
                    contribution clause, when the claim is higher than the sum
                    insured for one health insurance policy.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  3
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>Why should I buy a health insurance plan?</label>
                  <div>
                    {showcont === 3 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(3)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 3 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    A health insurance policy not only protects the insured
                    financially for future, but also offers relief in the
                    present. Lifestyle habits such as drinking, smoking, or
                    sedentary lifestyle invite health issues, which can be minor
                    or serious, may be expensive to treat. To stay financially
                    protected in such times, you need a reliable health
                    insurance plan that covers you at all times. Apart from
                    this, buying a health insurance policy also reduces your
                    overall tax liability by allowing you tax deductions on the
                    premium paid, under Section 80D of the Income Tax Act, 1961.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>How is health insurance premium determined?</label>
                  <div>
                    {showcont === 4 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(4)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 4 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Different health insurance plans have different premiums.
                    The insurance companies determine premiums after considering
                    various factors that are explained below:
                    <br />
                    <br /> 1. Type of Insurance Plan: Your health insurance
                    premium is based on the type of plan you choose. If you
                    choose a critical illness insurance plan, then the premium
                    will be high. If you choose an individual health insurance
                    policy, then the premium will be different from that of a
                    family floater plan. To know the difference in health
                    insurance premiums, you can use InsuranceDekho’s health
                    insurance premium calculator.
                    <br />
                    <br /> 2. Age of the Insured: With age, you become more
                    prone to health issues and are more likely to make health
                    insurance claims. Therefore, you are required to pay higher
                    premiums if you buy health insurance plans in later stages
                    of life. This is why it is recommended to buy health
                    insurance policy when young. <br />
                    <br /> 3. Policy Term: Most health insurance plans come for
                    a period of 1 year, 2 years, or 3 years. Greater the policy
                    term you choose, the greater will be your coverage, and
                    hence higher will be your health insurance premium, and vice
                    versa. <br />
                    <br /> 4. Lifestyle Habits: If you drink alcohol or smoke
                    regularly, then you may be denied health insurance
                    completely. However, there are some insurance companies that
                    cover you for the same, for which they charge high premiums.
                    This is because, with these lifestyle habits, you are more
                    prone to health issues.
                    <br />
                    <br /> 5.Family Medical History: Before deciding your health
                    insurance premium, the insurance company will ask about your
                    family medical history. This is because if someone in your
                    family has a certain disease that you are also vulnerable
                    to, then the insurance company will charge you a higher
                    premium due to increased risk.
                    <br />
                    <br /> 6. Sum Insured: Health insurance plans come with
                    different sum insured options that you can choose from as
                    per your budget and requirement. The higher the sum insured,
                    the higher the medical coverage and hence higher the health
                    insurance premium. But it is not recommended to compromise
                    on the sum insured to save on premiums. This is because it
                    will not cover you adequately in the time of need.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  5
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>
                    Can I cancel my health insurance policy? If yes, will I get
                    a refund for the same?
                  </label>
                  <div>
                    {showcont === 5 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(5)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 5 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Yes. You can cancel your health insurance policy. Every
                    health insurance company provides you a free look period to
                    understand the policy terms and conditions. During this
                    period, if you find any objectionable clause in your health
                    insurance policy, then you can cancel the policy and ask for
                    a refund. Note that you will receive the refund only if no
                    claim has been made during the policy term. The amount of
                    refund will be calculated after taking into consideration
                    the expenses incurred on medical check-ups, stamp duty, and
                    proportionate risk premium, i.e. the number of days you have
                    been covered for risks under the policy. To know more about
                    a premium refunds, it is recommended to go through the
                    policy document.
                  </label>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        {showdiv === 2 ? (
          <>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  1
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>What is Life Insurance ?</label>
                  <div>
                    {showcont === 1 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(1)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 1 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Life insurance is an insurance cover which provides a sum
                    assured to the family of the assured in the event of sudden
                    death. The plan also offers a survival benefit to the
                    assured if he/she survives the policy term.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  2
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>Why should I purchase life insurance?</label>
                  <div>
                    {showcont === 2 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(2)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 2 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Life insurance helps you attain financial security that
                    ensures your family’s life goals are not affected. Life
                    insurance also offers tax benefits on the premiums that a
                    policyholder pays for their life insurance. Moreover, life
                    insurance plans are affordable and the policyholder can
                    purchase additional benefits by purchasing a rider to
                    enhance their life cover.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  3
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>Which is the best life insurance plan?</label>
                  <div>
                    {showcont === 3 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(3)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 3 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Different life insurance plans have different features and
                    advantages. Thus, the definition of the best plan varies
                    from individual to individual. The best life insurance plan
                    is the one which best meets your requirements and budget.
                    However, among all the different types of life insurance
                    plans, the most preferred type of life insurance plan is
                    Term Insurance Plan because it provides high coverage at
                    nominal premium.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>
                    What are the factors which I should consider before buying a
                    life insurance policy?
                  </label>
                  <div>
                    {showcont === 4 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(4)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 4 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Before purchasing a life insurance plan you must check your
                    insurance objectives, your income, your life insurance
                    existing policies (if any), your assets, liabilities, and
                    your expenses.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  5
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>Is it safe to buy life insurance policy online?</label>
                  <div>
                    {showcont === 5 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(5)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 5 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Purchasing life insurance policy is easy, quick and
                    hassle-free and the payment is made through a safe gateway.
                    Buying policies online is less time consuming as well.
                  </label>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        {showdiv === 3 ? (
          <>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  1
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>What is a car insurance policy?</label>
                  <div>
                    {showcont === 1 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(1)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 1 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Car Insurance/Four Wheeler Insurance is an agreement between
                    an insurance company and a car owner under which the former
                    provides an insurance cover to the policyholder for
                    financial damages incurred by his/her car in unforeseen
                    events. Depending on the coverage, there are three types of
                    car insurance plans - third party car insurance policy,
                    standalone own-damage car insurance and comprehensive car
                    insurance policy. Car insurance renewal online process
                    provides instant e-Policy.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  2
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>Why should I buy car insurance?</label>
                  <div>
                    {showcont === 2 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(2)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 2 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    There are a number of benefits of owning four wheeler
                    insurance. Firstly, it helps you meet the legal requirement
                    of owning at least a third party car insurance cover.
                    Moreover, a car insurance policy helps you meet financial
                    liabilities that may arise towards a third party or
                    own-damages to your car due to a road accident or any other
                    unfortunate event.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  3
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>How much is car insurance premium?</label>
                  <div>
                    {showcont === 3 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(3)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 3 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    The premium rates for third-party car insurance price depend
                    on the engine capacity of the car and is fixed by the IRDAI.
                    However, the premium for standalone own-damage and
                    comprehensive car insurance policies varies from car to car
                    depending on a number of factors such as car's MMV (Make,
                    Model and Variant), RTO location, engine capacity,
                    registration city, fuel type,
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>What if my car insurance policy gets expired?</label>
                  <div>
                    {showcont === 4 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(4)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 4 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Every car insurance comes with a validity period, post which
                    you need to renew it to continue to enjoy its benefits.
                    However, even if you miss to get your car insurance plan
                    renewed by the expiry date, you can still get it renewed
                    till the next 90 days from the date of expiry. After the
                    expiry of this 90-day period, car insurance cannot be
                    renewed. In such a case, you need to purchase a new car
                    insurance policy.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  5
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>How to find my car insurance policy number?</label>
                  <div>
                    {showcont === 5 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(5)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 5 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Car insurance policy number is a unique 8 to 10 digit
                    number, given by your car insurance company for the
                    identification of your insurance account. It is generally
                    written on your insurance card or statements issued by the
                    insurance company. The number remains the same as long as
                    your policy is active with the respective insurance
                    provider.
                  </label>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        {showdiv === 4 ? <>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  1
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>What is a health insurance plan?</label>
                  <div>
                    {showcont === 1 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(1)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 1 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Health insurance policy is an agreement whereby an insurance
                    company agrees to undertake a guarantee to compensate the
                    insured for medical expenses in case of a medical emergency.
                    A health insurance policy protects the insured for several
                    surgical expenses, critical illnesses, and daycare expenses,
                    for a policy term, for up to the sum insured limit.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  2
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>
                    Can I purchase more than 1 health insurance plan? How are
                    the claims settled in this case?
                  </label>
                  <div>
                    {showcont === 2 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(2)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 2 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Yes. You can be covered under more than one health insurance
                    plan. In this case, the claims are settled as per the
                    contribution clause, when the claim is higher than the sum
                    insured for one health insurance policy.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  3
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>Why should I buy a health insurance plan?</label>
                  <div>
                    {showcont === 3 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(3)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 3 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    A health insurance policy not only protects the insured
                    financially for future, but also offers relief in the
                    present. Lifestyle habits such as drinking, smoking, or
                    sedentary lifestyle invite health issues, which can be minor
                    or serious, may be expensive to treat. To stay financially
                    protected in such times, you need a reliable health
                    insurance plan that covers you at all times. Apart from
                    this, buying a health insurance policy also reduces your
                    overall tax liability by allowing you tax deductions on the
                    premium paid, under Section 80D of the Income Tax Act, 1961.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>How is health insurance premium determined?</label>
                  <div>
                    {showcont === 4 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(4)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 4 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Different health insurance plans have different premiums.
                    The insurance companies determine premiums after considering
                    various factors that are explained below:
                    <br />
                    <br /> 1. Type of Insurance Plan: Your health insurance
                    premium is based on the type of plan you choose. If you
                    choose a critical illness insurance plan, then the premium
                    will be high. If you choose an individual health insurance
                    policy, then the premium will be different from that of a
                    family floater plan. To know the difference in health
                    insurance premiums, you can use InsuranceDekho’s health
                    insurance premium calculator.
                    <br />
                    <br /> 2. Age of the Insured: With age, you become more
                    prone to health issues and are more likely to make health
                    insurance claims. Therefore, you are required to pay higher
                    premiums if you buy health insurance plans in later stages
                    of life. This is why it is recommended to buy health
                    insurance policy when young. <br />
                    <br /> 3. Policy Term: Most health insurance plans come for
                    a period of 1 year, 2 years, or 3 years. Greater the policy
                    term you choose, the greater will be your coverage, and
                    hence higher will be your health insurance premium, and vice
                    versa. <br />
                    <br /> 4. Lifestyle Habits: If you drink alcohol or smoke
                    regularly, then you may be denied health insurance
                    completely. However, there are some insurance companies that
                    cover you for the same, for which they charge high premiums.
                    This is because, with these lifestyle habits, you are more
                    prone to health issues.
                    <br />
                    <br /> 5.Family Medical History: Before deciding your health
                    insurance premium, the insurance company will ask about your
                    family medical history. This is because if someone in your
                    family has a certain disease that you are also vulnerable
                    to, then the insurance company will charge you a higher
                    premium due to increased risk.
                    <br />
                    <br /> 6. Sum Insured: Health insurance plans come with
                    different sum insured options that you can choose from as
                    per your budget and requirement. The higher the sum insured,
                    the higher the medical coverage and hence higher the health
                    insurance premium. But it is not recommended to compromise
                    on the sum insured to save on premiums. This is because it
                    will not cover you adequately in the time of need.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  5
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>
                    Can I cancel my health insurance policy? If yes, will I get
                    a refund for the same?
                  </label>
                  <div>
                    {showcont === 5 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(5)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 5 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Yes. You can cancel your health insurance policy. Every
                    health insurance company provides you a free look period to
                    understand the policy terms and conditions. During this
                    period, if you find any objectionable clause in your health
                    insurance policy, then you can cancel the policy and ask for
                    a refund. Note that you will receive the refund only if no
                    claim has been made during the policy term. The amount of
                    refund will be calculated after taking into consideration
                    the expenses incurred on medical check-ups, stamp duty, and
                    proportionate risk premium, i.e. the number of days you have
                    been covered for risks under the policy. To know more about
                    a premium refunds, it is recommended to go through the
                    policy document.
                  </label>
                </div>
              ) : null}
            </div>
          </> : null}
        {showdiv === 5 ? <>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  1
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>What is a health insurance plan?</label>
                  <div>
                    {showcont === 1 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(1)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 1 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Health insurance policy is an agreement whereby an insurance
                    company agrees to undertake a guarantee to compensate the
                    insured for medical expenses in case of a medical emergency.
                    A health insurance policy protects the insured for several
                    surgical expenses, critical illnesses, and daycare expenses,
                    for a policy term, for up to the sum insured limit.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  2
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>
                    Can I purchase more than 1 health insurance plan? How are
                    the claims settled in this case?
                  </label>
                  <div>
                    {showcont === 2 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(2)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 2 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Yes. You can be covered under more than one health insurance
                    plan. In this case, the claims are settled as per the
                    contribution clause, when the claim is higher than the sum
                    insured for one health insurance policy.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  3
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>Why should I buy a health insurance plan?</label>
                  <div>
                    {showcont === 3 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(3)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 3 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    A health insurance policy not only protects the insured
                    financially for future, but also offers relief in the
                    present. Lifestyle habits such as drinking, smoking, or
                    sedentary lifestyle invite health issues, which can be minor
                    or serious, may be expensive to treat. To stay financially
                    protected in such times, you need a reliable health
                    insurance plan that covers you at all times. Apart from
                    this, buying a health insurance policy also reduces your
                    overall tax liability by allowing you tax deductions on the
                    premium paid, under Section 80D of the Income Tax Act, 1961.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  4
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>How is health insurance premium determined?</label>
                  <div>
                    {showcont === 4 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(4)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 4 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Different health insurance plans have different premiums.
                    The insurance companies determine premiums after considering
                    various factors that are explained below:
                    <br />
                    <br /> 1. Type of Insurance Plan: Your health insurance
                    premium is based on the type of plan you choose. If you
                    choose a critical illness insurance plan, then the premium
                    will be high. If you choose an individual health insurance
                    policy, then the premium will be different from that of a
                    family floater plan. To know the difference in health
                    insurance premiums, you can use InsuranceDekho’s health
                    insurance premium calculator.
                    <br />
                    <br /> 2. Age of the Insured: With age, you become more
                    prone to health issues and are more likely to make health
                    insurance claims. Therefore, you are required to pay higher
                    premiums if you buy health insurance plans in later stages
                    of life. This is why it is recommended to buy health
                    insurance policy when young. <br />
                    <br /> 3. Policy Term: Most health insurance plans come for
                    a period of 1 year, 2 years, or 3 years. Greater the policy
                    term you choose, the greater will be your coverage, and
                    hence higher will be your health insurance premium, and vice
                    versa. <br />
                    <br /> 4. Lifestyle Habits: If you drink alcohol or smoke
                    regularly, then you may be denied health insurance
                    completely. However, there are some insurance companies that
                    cover you for the same, for which they charge high premiums.
                    This is because, with these lifestyle habits, you are more
                    prone to health issues.
                    <br />
                    <br /> 5.Family Medical History: Before deciding your health
                    insurance premium, the insurance company will ask about your
                    family medical history. This is because if someone in your
                    family has a certain disease that you are also vulnerable
                    to, then the insurance company will charge you a higher
                    premium due to increased risk.
                    <br />
                    <br /> 6. Sum Insured: Health insurance plans come with
                    different sum insured options that you can choose from as
                    per your budget and requirement. The higher the sum insured,
                    the higher the medical coverage and hence higher the health
                    insurance premium. But it is not recommended to compromise
                    on the sum insured to save on premiums. This is because it
                    will not cover you adequately in the time of need.
                  </label>
                </div>
              ) : null}
            </div>
            <div
              style={{ borderBottom: "1px solid lightgrey", marginTop: "1%" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: 30,
                    height: 30,
                    border: "1px solid grey",
                    borderRadius: "50%",
                    display: "grid",
                    placeItems: "center",
                    marginLeft: "1%",
                  }}
                >
                  5
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    justifyContent: "space-between",
                    paddingRight: "2%",
                  }}
                >
                  <label>
                    Can I cancel my health insurance policy? If yes, will I get
                    a refund for the same?
                  </label>
                  <div>
                    {showcont === 5 ? (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(0)}
                      >
                        -
                      </b>
                    ) : (
                      <b 
                        style={{cursor:"pointer",  fontSize: 30 }}
                        onClick={() => setShowcont(5)}
                      >
                        +
                      </b>
                    )}
                  </div>
                </div>
              </div>
              {showcont === 5 ? (
                <div
                  style={{
                    width: "100%",
                    paddingLeft: "5%",
                    textAlign: "justify",
                  }}
                >
                  <label>
                    Yes. You can cancel your health insurance policy. Every
                    health insurance company provides you a free look period to
                    understand the policy terms and conditions. During this
                    period, if you find any objectionable clause in your health
                    insurance policy, then you can cancel the policy and ask for
                    a refund. Note that you will receive the refund only if no
                    claim has been made during the policy term. The amount of
                    refund will be calculated after taking into consideration
                    the expenses incurred on medical check-ups, stamp duty, and
                    proportionate risk premium, i.e. the number of days you have
                    been covered for risks under the policy. To know more about
                    a premium refunds, it is recommended to go through the
                    policy document.
                  </label>
                </div>
              ) : null}
            </div>
          </> : null}
      </div>


      {/* FOOTER */}
      <div style={{width:"100%", height:"50vh", borderTop:"2px solid grey", borderBottom:"2px solid grey" , marginTop:"5%", display:"flex", justifyContent:"space-evenly", alignItems:"center"}}>
      <label>Email:- support@insurance.com</label>
      <img style={{ width:"20%" }} onClick={()=>navigate("/Login")} src={logo} />
      <a href="tel:+91 8889223659" style={{color:"black"}}> Call us -8889223659</a>
      </div>
    </div>
  );
}

export default Home;
