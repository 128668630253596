import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import contact from './contact.css'
import axios from 'axios'
import { baseUrl } from './baseURL'

function Contact() {
    useEffect(()=>{window.scrollTo(0,0)},[])
    
    const [list, setList] = useState([])
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [number, setNumber] = useState("")
    const [city, setCity] = useState("")
    
        const postData = ()=>{
            const item={
                name:name,
                email:email,
                number:number,
                city:city
            }
            axios.post(baseUrl+"contact", item).then(()=>{setName(""); setEmail(""); setNumber(""); setCity("") })
        }
  
        return (

    <div>
        <Header/>
        <div style={{width:"100%", height:"13vh"}}></div>
        <div style={{width:"100%", height:"100vh", backgroundColor:"#333333", }}>
            <div className='contact'>
                    <label style={{fontSize:30}}>Get Connected Now</label>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <label>Full Name</label>
                        <input value={name} placeholder='Full Name' onChange={(e)=>setName(e.target.value)}/>
                    </div>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <label>Email</label>
                        <input value={email} placeholder='Email' onChange={(e)=>setEmail(e.target.value)}/>
                    </div>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <label>Phone No.</label>
                        <input value={number} placeholder='Phone No.' onChange={(e)=>setNumber(e.target.value)}/>
                    </div>
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <label>City</label>
                        <input value={city} placeholder='City' onChange={(e)=>setCity(e.target.value)}/>
                    </div>
                    <div>
                        <button className='send-btn' onClick={()=>postData()}>Send</button>
                    </div>
            </div>
        </div>
        <br/>
        <Footer/>
    </div>
  )
}

export default Contact