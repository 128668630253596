import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Sidebar1 from "./Sidebar1";
import { CSVLink } from "react-csv";
import Logo from "./img2.png";
import ReactWhatsapp from "react-whatsapp";
import { baseUrl } from "./baseURL";
function AllDetails() {
  useEffect(() => {
    const isLogin = sessionStorage.getItem("isLogin");
    if (!isLogin) {
      navigate("/login");
    }
  }, []);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);

  const getData = () => {
    axios.get(baseUrl + "details").then((res) => {
      setData(res.data.data);
      setData1(res.data.data);
    });
  };
  const filter = (x) => {
    const list = data1.filter((i) => i.firstName.toLowerCase().includes(x));
    setData(list);
  };
  const filter1 = (y) => {
    const list1 = data1.filter((i) => i.city.toLowerCase().includes(y));
    setData(list1);
  };

  const delData = (x) => {
    axios.delete(baseUrl + "details/" + x).then(() => getData());
  };

  const [slide, setSlide] = useState(false);

  const [label1, setLabel1] = useState(false);
  const [label2, setLabel2] = useState(false);
  const [label3, setLabel3] = useState(false);
  const [label4, setLabel4] = useState(false);
  const [label5, setLabel5] = useState(false);

  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [hover5, setHover5] = useState(false);
  const [wpi, setWpi] = useState("");

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Sidebar />
      <div className="newside" style={{ width: slide ? "50%" : 0 }}>
        <div
          style={{
            width: "100%",
            height: "10vh",
            marginTop: -150,
          }}
        >
          <img style={{ width: "100%", marginTop: -80 }} src={Logo}></img>
        </div>
        <div
          style={{
            width: "80%",
            alignSelf: "center",
            backgroundColor: "GrayText",
            height: 1,
            marginTop: 20,
          }}
        ></div>
        <div
          className={label1 ? "abc" : hover1 ? "xyz" : "ss"}
          onMouseOver={() => setHover1(true)}
          onMouseLeave={() => setHover1(false)}
          onClick={() => {
            setLabel1(true);
            setLabel2(false);
            setLabel3(false);
            setLabel4(false);
            setLabel5(false);
          }}
          style={{
            width: "85%",
            height: "8vh",

            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 10,
            alignSelf: "center",
          }}
        >
          <div style={{ width: 30 }}>
            <i class="fa fa-tachometer fa-2x" aria-hidden="true"></i>
          </div>
          <label
            onClick={() => navigate("/AllDetails")}
            className="ww"
            style={{ fontSize: 20, fontWeight: "bold", marginLeft: 20 }}
          >
            Dashboard
          </label>
        </div>

        <div
          className={label2 ? "abc" : hover2 ? "xyz" : "ss"}
          onMouseOver={() => setHover2(true)}
          onMouseLeave={() => setHover2(false)}
          onClick={() => {
            setLabel1(false);
            setLabel2(true);
            setLabel3(false);
            setLabel4(false);
            setLabel5(false);
          }}
          style={{
            width: "85%",
            height: "8vh",
            alignItems: "center",

            display: "flex",
            borderRadius: 10,
            alignSelf: "center",
          }}
        >
          <div style={{ width: 30 }}>
            <i class="fa fa-user-plus fa-2x" aria-hidden="true"></i>
          </div>
          <label
            onClick={() => navigate("/register")}
            className="ww"
            style={{ fontSize: 20, fontWeight: "bold", marginLeft: 20 }}
          >
            Registration
          </label>
        </div>
        <div
          className={label3 ? "abc" : hover3 ? "xyz" : "ss"}
          onMouseOver={() => setHover3(true)}
          onMouseLeave={() => setHover3(false)}
          onClick={() => {
            setLabel1(false);
            setLabel2(false);
            setLabel3(true);
            setLabel4(false);
            setLabel5(false);
            navigate("/Medical");
          }}
          style={{
            width: "85%",
            height: "8vh",
            alignItems: "center",

            display: "flex",
            borderRadius: 10,
            alignSelf: "center",
          }}
        >
          <div style={{ width: 30 }}>
            <i class="fa fa-heartbeat fa-2x" aria-hidden="true"></i>
          </div>
          <label
            className="ww"
            style={{ fontSize: 20, fontWeight: "bold", marginLeft: 20 }}
          >
            Medical Insurance
          </label>
        </div>
        <div
          className={label4 ? "abc" : hover4 ? "xyz" : "ss"}
          onMouseOver={() => setHover4(true)}
          onMouseLeave={() => setHover4(false)}
          onClick={() => {
            setLabel1(false);
            setLabel2(false);
            setLabel3(false);
            setLabel4(true);
            setLabel5(false);
            navigate("/Motor");
          }}
          style={{
            width: "85%",
            height: "8vh",
            alignItems: "center",

            display: "flex",
            borderRadius: 10,
            alignSelf: "center",
          }}
        >
          <div style={{ width: 30 }}>
            <i class="fa fa-tachometer fa-2x" aria-hidden="true"></i>
          </div>
          <label
            className="ww"
            style={{ fontSize: 20, fontWeight: "bold", marginLeft: 20 }}
          >
            Motor Insurance
          </label>
        </div>
        <div
          className={label5 ? "abc" : hover5 ? "xyz" : "ss"}
          onMouseOver={() => setHover5(true)}
          onMouseLeave={() => setHover5(false)}
          onClick={() => {
            setLabel1(false);
            setLabel2(false);
            setLabel3(false);
            setLabel4(false);
            setLabel5(true);
            navigate("/Life");
          }}
          style={{
            width: "85%",
            height: "8vh",
            alignItems: "center",

            display: "flex",
            borderRadius: 10,
            alignSelf: "center",
          }}
        >
          <div style={{ width: 30 }}>
            <i class="fa  fa-life-ring    fa-2x" aria-hidden="true"></i>
          </div>
          <label
            className="ww"
            style={{ fontSize: 20, fontWeight: "bold", marginLeft: 20 }}
          >
            Life Insurance
          </label>
        </div>
        <div
          className={label5 ? "abc" : hover5 ? "xyz" : "ss"}
          onMouseOver={() => setHover5(true)}
          onMouseLeave={() => setHover5(false)}
          onClick={() => {
            setLabel1(false);
            setLabel2(false);
            setLabel3(false);
            setLabel4(false);
            setLabel5(true);
            navigate("/Fire");
          }}
          style={{
            width: "85%",
            height: "8vh",
            alignItems: "center",

            display: "flex",
            borderRadius: 10,
            alignSelf: "center",
          }}
        >
          <div style={{ width: 30 }}>
            <i class="fa fa-free-code-camp   fa-2x" aria-hidden="true"></i>
          </div>
          <label
            className="ww"
            style={{ fontSize: 20, fontWeight: "bold", marginLeft: 20 }}
          >
            Fire Insurance
          </label>
        </div>
      </div>

      <div
        style={{
          minWidth: "80%",
          // backgroundColor: "rgba(231, 57, 62, 0.4)",
          paddingRight: "0.5%",
        }}
      >
        {slide === false ? (
          <i
            class="fa fa-bars fa-2x navbar"
            onClick={() => setSlide(true)}
            aria-hidden="true"
          ></i>
        ) : (
          <i
            class="fa fa-times fa-2x navbar"
            onClick={() => setSlide(false)}
            aria-hidden="true"
          ></i>
        )}

        <div
          style={{
            display: "flex",
            width: "100%",
            // justifyContent: "space-around",
            height: "8vh",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "25%",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <label>Name:</label>
            <input
              onChange={(e) => {
                filter(e.target.value);
              }}
              placeholder="Name"
              style={{
                border: "1px solid grey",
                borderRadius: 5,
                height: 30,
                width: 200,
              }}
            ></input>
          </div>
          <button
            style={{
              height: "5vh",
              border: "none",
              borderRadius: 10,
              backgroundColor: "#E7393E",
              color: "white",
            }}
            onClick={() => navigate("/register")}
          >
            + Add New Member
          </button>
        </div>
        <table
          style={{ width: "100%", backgroundColor: "white", color: "#05386B" }}
          class="table text-nowrap"
        >
          <thead style={{ backgroundColor: "#E7393E", color: "whitesmoke" }}>
            <tr>
              <th scope="col">S No.</th>
              <th scope="col">Name</th>
              <th scope="col">City</th>
              <th scope="col">Number</th>
              <th scope="col">View</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
              <th scope="col">Chat</th>
            </tr>
          </thead>
          <tbody>
            {data.map((i, n) => (
              <tr
                style={{
                  fontWeight: "bold",
                  backgroundColor: n % 2 === 0 ? null : "rgba(255,0,0,0.3)",
                }}
              >
                <th scope="row">{n + 1}</th>

                <td>
                  {i.firstName} {i.middleName} {i.lastName}
                </td>
                <td>{i.city}</td>
                <td>{i.number}</td>
                <td style={{}}>
                  <i
                    onClick={() => {
                      localStorage.setItem("memberDetails", JSON.stringify(i));
                      navigate("/MemberDetails");
                    }}
                    class="fa fa-eye"
                    style={{
                      color: "rgba(107 168 81)",
                      cursor: "pointer",
                      fontSize: 18,
                    }}
                    aria-hidden="true"
                  ></i>
                </td>
                <td style={{}}>
                  <i
                    onClick={() => {
                      localStorage.setItem("memberDetails", JSON.stringify(i));
                      navigate("/EditDetails");
                    }}
                    class="fa fa-pencil"
                    style={{
                      color: "rgba(237 162 67)",
                      cursor: "pointer",
                      fontSize: 18,
                    }}
                    aria-hidden="true"
                  ></i>
                </td>
                <td>
                  <i
                    onClick={() => {
                      if (window.confirm("Are You Sure")) delData(i._id);
                    }}
                    class="fa fa-trash"
                    style={{
                      color: "rgba(222 82 70)",
                      cursor: "pointer",
                      fontSize: 18,
                    }}
                    aria-hidden="true"
                  ></i>
                </td>

                <td>
                  <label for="rw">
                    {wpi === n && (
                      <ReactWhatsapp
                        style={{ display: "none" }}
                        id="rw"
                        number={i.number}
                        message="hi!"
                      ></ReactWhatsapp>
                    )} 
                    <i
                      style={{ color: "green", fontSize: 20 }}
                      class="fa fa-whatsapp "
                      aria-hidden="true"
                      onClick={() => setWpi(n)}
                    ></i>
                  </label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AllDetails;
