import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./img2.png";
import isilogo from '../ISI.png'

function Sidebar() {
  // useEffect(()=>{
  //   const isLogin = sessionStorage.getItem("isLogin")
  //   if(!isLogin){
  //     navigate('/login')
  //   }
  // },[])
  const navigate = useNavigate();
  const [label1, setLabel1] = useState(false);
  const [label2, setLabel2] = useState(false);
  const [label3, setLabel3] = useState(false);
  const [label4, setLabel4] = useState(false);
  const [label5, setLabel5] = useState(false);

  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [hover4, setHover4] = useState(false);
  const [hover5, setHover5] = useState(false);

  // const color = ()=>{
  //     if(label1||label2||label3||label4||label5){
  //         return(blue)
  //     }else {
  //         return(GrayText)
  //     }
  // }

  return (
    <div className="side"
    >
      <div
        style={{
          width: "100%",
          height: "20%", display:"grid", placeItems:"center"
        }}
      onClick={()=>navigate("/alldetails")}
      >
        <img style={{ width: "100%", }} src={isilogo}></img>
      </div>
      <div
        style={{
          width: "80%", 
          alignSelf: "center",
          backgroundColor: "#E7393E",
          height: 1,
          marginTop: 20,
        }}
      ></div>
      <div
        className={label1 ? "abc" : hover1 ? "xyz" : "ss"}
        // onMouseOver={() => setHover1(true)}
        // onMouseLeave={() => setHover1(false)}
        onClick={() => {
          setLabel1(true);
          setLabel2(false);
          setLabel3(false);
          setLabel4(false);
          setLabel5(false);
        }}
        
        style={{
          width: "85%",
          height: "8vh",
          
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderRadius: 10,
          alignSelf: "center",
        }}
      >
        {/* <div style={{width:30}}><i class="fa fa-tachometer fa-2x" aria-hidden="true"></i></div> */}
        <label  onClick={()=>navigate("/AllDetails")} className="ww" style={{fontSize: 17, fontWeight: "bold",marginLeft:10 }}>
          Dashboard
        </label>
      </div>
      {/* <div style={{height:2, width:"100%", backgroundColor:"#05386B"}}></div> */}

      <div
        className={label2 ? "abc" : hover2 ? "xyz" : "ss"}
        // onMouseOver={() => setHover2(true)}
        // onMouseLeave={() => setHover2(false)}
        onClick={() => {
          setLabel1(false);
          setLabel2(true);
          setLabel3(false);
          setLabel4(false);
          setLabel5(false);
        }}
        style={{
          width: "85%",
          height: "8vh",
          alignItems: "center",
         
          display: "flex",
          borderRadius: 10,
          alignSelf: "center",
        }}
      >
        {/* <div style={{width:30}}><i class="fa fa-user-plus fa-2x" aria-hidden="true"></i></div> */}
        <label onClick={()=>navigate("/register")} className="ww" style={{fontSize: 17, fontWeight: "bold",marginLeft:10 }}>
          Registration
        </label>
      </div>
      <div
        className={label3 ? "abc" : hover3 ? "xyz" : "ss"}
        // onMouseOver={() => setHover3(true)}
        // onMouseLeave={() => setHover3(false)}
        onClick={() => {
          setLabel1(false);
          setLabel2(false);
          setLabel3(true);
          setLabel4(false);
          setLabel5(false);
          navigate('/Medical')
        }}
        style={{
          width: "85%",
          height: "8vh",
          alignItems: "center",
          
          display: "flex",
          borderRadius: 10,
          alignSelf: "center",
        }}
      >
        {/* <div style={{width:30}}><i class="fa fa-heartbeat fa-2x" aria-hidden="true"></i></div> */}
        <label className="ww" style={{fontSize: 17, fontWeight: "bold",marginLeft:10}}>
          Medical Insurance
        </label>
      </div>
      <div
        className={label4 ? "abc" : hover4 ? "xyz" : "ss"}
        // onMouseOver={() => setHover4(true)}
        // onMouseLeave={() => setHover4(false)}
        onClick={() => {
          setLabel1(false);
          setLabel2(false);
          setLabel3(false);
          setLabel4(true);
          setLabel5(false);
          navigate('/Motor')

        }}
        style={{
          width: "85%",
          height: "8vh",
          alignItems: "center",
       
          display: "flex",
          borderRadius: 10,
          alignSelf: "center",
        }}
      >
        {/* <div style={{width:30}}><i class="fa fa-tachometer fa-2x" aria-hidden="true"></i></div> */}
        <label className="ww" style={{fontSize: 17, fontWeight: "bold",marginLeft:10 }}>
          Motor Insurance
        </label>
      </div>
      <div
        className={label5 ? "abc" : hover5 ? "xyz" : "ss"}
        // onMouseOver={() => setHover5(true)}
        // onMouseLeave={() => setHover5(false)}
        onClick={() => {
          setLabel1(false);
          setLabel2(false);
          setLabel3(false);
          setLabel4(false);
          setLabel5(true);
          navigate('/Life')

        }}
        style={{
          width: "85%",
          height: "8vh",
          alignItems: "center",
         
          display: "flex",
          borderRadius: 10,
          alignSelf: "center",
        }}
      >
        {/* <div style={{width:30}}><i class="fa  fa-life-ring    fa-2x" aria-hidden="true"></i></div> */}
        <label className="ww" style={{fontSize: 17, fontWeight: "bold",marginLeft:10 }}>
          Life Insurance
        </label>
      </div>
      <div
        className={label5 ? "abc" : hover5 ? "xyz" : "ss"}
        // onMouseOver={() => setHover5(true)}
        // onMouseLeave={() => setHover5(false)}
        onClick={() => {
          setLabel1(false);
          setLabel2(false);
          setLabel3(false);
          setLabel4(false);
          setLabel5(true);
          navigate('/Fire')
        }}
        style={{
          width: "85%",
          height: "8vh",
          alignItems: "center",
          display: "flex",
          borderRadius: 10,
          alignSelf: "center",
        }}
      >
        {/* <div style={{width:30}}><i class="fa fa-free-code-camp   fa-2x" aria-hidden="true"></i></div> */}
        <label className="ww" style={{fontSize: 17, fontWeight: "bold",marginLeft:10 }}>
          Fire Insurance
        </label>
      </div>
      <div
        className={label5 ? "abc" : hover5 ? "xyz" : "ss"}
        // onMouseOver={() => setHover5(true)}
        // onMouseLeave={() => setHover5(false)}
        onClick={() => {
          navigate('/quries')
        }}
        style={{
          width: "85%",
          height: "8vh",
          alignItems: "center",
          display: "flex",
          borderRadius: 10,
          alignSelf: "center",
        }}
      >
        {/* <div style={{width:30}}><i class="fa fa-sign-out   fa-2x" aria-hidden="true"></i></div> */}
        <label className="ww" style={{fontSize: 17, fontWeight: "bold",marginLeft:10 }}>
          Quries Contact
        </label>
      </div>
      <div
        className={label5 ? "abc" : hover5 ? "xyz" : "ss"}
        // onMouseOver={() => setHover5(true)}
        // onMouseLeave={() => setHover5(false)}
        onClick={() => {
          sessionStorage.removeItem("isLogin")
          setLabel1(false);
          setLabel2(false);
          setLabel3(false);
          setLabel4(false);
          setLabel5(true);
          navigate('/login')
        }}
        style={{
          width: "85%",
          height: "8vh",
          alignItems: "center",
         
          display: "flex",
          borderRadius: 10,
          alignSelf: "center",
        }}
      >
        {/* <div style={{width:30}}><i class="fa fa-sign-out   fa-2x" aria-hidden="true"></i></div> */}
        <label className="ww" style={{fontSize: 17, fontWeight: "bold",marginLeft:10 }}>
          Logout
        </label>
      </div>
    </div>
  );
}

export default Sidebar;
// import React, { useState } from "react";

// import { useNavigate } from "react-router-dom";
// function Sidebar() {
//   const [showSide, setShowSide] = useState(false);
//   const [showrep,setShowrep]=useState(false)
//   const [showCall,setShowCall]=useState(false)
//   const [showDist,setShowDist]=useState(false)
//   const [showmaster,setShowMaster]=useState(false)
//   const [showCallCenter,setShowCallCenter]=useState(false)
//   const [showCC,setShowCC]=useState(false)
//   const [showCC1,setShowCC1]=useState(false)
//   const [showWare,setShowWare]=useState(false)
//   const [showWare1,setShowWare1]=useState(false)
//   const [showWare2,setShowWare2]=useState(false)
//   const [showParty,setShowParty]=useState(false)
//   const navigate = useNavigate("");
//   return (
//     <div
//       style={{
//         width: showSide ? 220 : 55,
//         height: "100vh",
//         backgroundColor: "#151934",
//         position: "fixed",

//         transition: "0.5s",
//         overflow: "hidden",
//         overflowY: "auto",
//         zIndex:50
//       }}
//       onMouseOver={() => setShowSide(true)}
//       onMouseLeave={() =>{
//         setShowrep(false)
//         setShowSide(false)
//         setShowCall(false)
//         setShowDist(false)
//         setShowMaster(false)
//         setShowCallCenter(false)
//         setShowCC(false)
//         setShowCC1(false)
//         setShowWare(false)
//         setShowWare1(false)
//         setShowWare2(false)
//         setShowParty(false)
//       }}
//     >

//     </div>
//   );
// }

// export default Sidebar;
