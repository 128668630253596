
import { HashRouter,Routes,Route } from "react-router-dom";
import './App.css';
import AboutUs from "./pages/AboutUs";
import Login from './pages/Login';
import Register from "./pages/Register";
import Register1 from "./pages/Register1";
import EditDetails from "./pages/EditDetails";
import AllDetails from "./pages/AllDetails";
import MemberDetails from "./pages/MemberDetails";
import Sidebar from "./pages/Sidebar";
import Medical from "./pages/Medical";
import Motor from "./pages/Motor";
import Life from "./pages/Life";
import Fire from "./pages/Fire";
import Home from "./pages/Home";
import Home1 from "./pages/Home1";
import Header from './pages/Header'
import Footer from './pages/Footer'
import Contact from './pages/Contact'
import HealthInsurance from './pages/HealthInsurance'
import TermInsurance from './pages/TermInsurance'
import BikeInsurance from './pages/BikeInsurance'
import CarInsurance from './pages/CarInsurance'
import TravelInsurance from './pages/TravelInsurance'
import PetInsurance from './pages/PetInsurance'
import Investment from "./pages/Investment";
import LifeInsurance from "./pages/LifeInsurance";
import Quries from "./pages/Quries";
import HomeInsurance from "./pages/HomeInsurance";
import EVInsurance from "./pages/EVInsurance";
import CommercialInsurance from "./pages/CommercialInsurance";
import GovScheme from "./pages/GovScheme";


function App() {
  return (
 
      <HashRouter>
        <Routes>
        <Route exact path='/Home' element={< Home />} />
        <Route exact path='/Header' element={< Header />} />
        <Route exact path='/' element={< Home1 />} />
        <Route exact path='/Login' element={< Login />} />
        <Route exact path='/Contact' element={< Contact />} />
        <Route exact path='/AboutUs' element={< AboutUs/>} />
        <Route exact path='/Register' element={< Register />} />
        <Route exact path='/UserRegister' element={< Register1 />} />
        <Route exact path='/EditDetails' element={< EditDetails />} />
        <Route exact path='/AllDetails' element={<AllDetails />} />
        <Route exact path='/MemberDetails' element={<MemberDetails/>} />
        <Route exact path='/Sidebar' element={<Sidebar/>} />
        <Route exact path='/Medical' element={<Medical/>} />
        <Route exact path='/Fire' element={<Fire/>} />
        <Route exact path='/Motor' element={<Motor/>} />
        <Route exact path='/Life' element={<Life/>} />
        <Route exact path='/Footer' element={<Footer/>} />
        <Route exact path='/HealthInsurance' element={<HealthInsurance/>} />
        <Route exact path='/TermInsurance' element={<TermInsurance/>} />
        <Route exact path='/BikeInsurance' element={<BikeInsurance/>} />
        <Route exact path='/CarInsurance' element={<CarInsurance/>} />
        <Route exact path='/TravelInsurance' element={<TravelInsurance/>} />
        <Route exact path='/PetInsurance' element={<PetInsurance/>} />
        <Route exact path='/Investment' element={<Investment/>} />
        <Route exact path='/LifeInsurance' element={<LifeInsurance/>} />
        <Route exact path='/Quries' element={<Quries/>} />
        <Route exact path='/HomeInsurance' element={<HomeInsurance/>} />
        <Route exact path='/EVInsurance' element={<EVInsurance/>} />
        <Route exact path='/CommercialInsurance' element={<CommercialInsurance/>} />
        <Route exact path='/GovScheme' element={<GovScheme/>} />
        </Routes>
        <script src="bower_components/aos/dist/aos.js"></script>
      </HashRouter>
      
  )
}

export default App;
