import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import Search  from '../search.png'
import thunder  from '../thunder.png'
import heart  from '../heart.png'

function BikeInsurance() {
    useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
        <Header/>
        <div style={{width:"100%", height:"15vh"}}></div>
        <div style={{width:"80%", minHeight:"50vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:30, color:"#E7393E"}}>What is two wheeler insurance?</label>
            <div className='car-div1' style={{display:"flex", marginTop:"5%"}}>
                 <div className='car-div1-sub' style={{width:"70%", textAlign:"justify"}}>
                     <label style={{fontSize:18, fontWeight:"lighter"}}>Two wheeler insurance, also known as bike insurance, is an agreement between the insurance company and the bike owner wherein the insurer covers the cost of damages caused to a third party and in some cases even the bike owner and his bike. A two wheeler insurance policy protects the bike owner against unforeseen events such as theft, natural disasters, man made calamities and even damages caused to third person or property. As per the motor vehicles act, 1988, it is mandatory for every vehicle owner to purchase a third party insurance to get protection against death, disability or damages caused to third person or property. Buying comprehensive two wheeler insurance is a matter of choice depending on the requirements of the bike owner.
                     </label>
                 </div>
                 <div className='car-div1-sub' style={{width:"30%"}}>
                     <img src="https://static.insurancedekho.com/pwa/img/bike-landing.svg"/>
                 </div>
                 </div>
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", marginTop:"10%", textAlign:"center"}}>
            <label style={{color:"#E9373E", fontSize:30}}>Different Types of Bike Insurance</label>     
            <div className='car-div2' style={{width:"100%", display:"flex", justifyContent:"space-between", marginTop:"5%", marginBottom:"5%"}}>
                <div className='car-div2-sub' style={{boxShadow:"0px 0px 5px grey" ,width:"30%", minHeight:"80vh", textAlign:"start", padding:"2%"}}>
                    <img width="15%" src={thunder}/><br/>
                    <b style={{fontSize:15}}>Comprehensive Bike Insurance </b>
                    <div style={{textAlign:"justify"}}>
                    <br/><label style={{fontSize:15, fontWeight:"lighter"}}>A comprehensive two-wheeler policy gives complete coverage by paying your financial liabilities that you may incur towards a third party and by providing compensation for expenses arising due to own-damages sustained by your bike. This policy shields your bike against damages it sustains due to an accident, fire, theft, man-made disasters, natural calamities, self-ignition, explosion, and so on. You can also enhance its coverage by paying an additional premium to purchase add-on covers like zero depreciation cover, roadside assistance cover, engine cover, etc.</label>
                </div>
                </div>
                
            <div className='car-div2-sub' style={{boxShadow:"0px 0px 5px grey" ,width:"30%", minHeight:"80vh", textAlign:"start", padding:"2%"}}>
                    <img width="15%" src={Search}/><br/>
                    <b style={{fontSize:15}}>Third Party Bike Insurance</b>
                    <div style={{textAlign:"justify"}}>
                    <br/><label style={{fontSize:15, fontWeight:"lighter"}}>In India, a third-party two-wheeler insurance plan is a compulsory requirement to ride a bike. It is also known as liability-only policy which covers bodily injuries, temporary or permanent disability, and death of the third party person due to an accident or a mishap with the involvement of the insured’s bike. This motorcycle or scooter insurance policy also covers the property damage expenses sustained by the third party up to Rs. 1 Lakh, but does not cover damages incurred by the insured’s bike or the insured person.</label>
                </div>
                </div>
                
            <div className='car-div2-sub' style={{boxShadow:"0px 0px 5px grey" ,width:"30%", minHeight:"80vh", textAlign:"start", padding:"2%"}}>
                    <img width="15%" src={heart}/><br/>
                    <b style={{fontSize:15}}>Own-Damage Bike Insurance</b>
                    <div style={{textAlign:"justify"}}>
                    <br/><label style={{fontSize:15, fontWeight:"lighter"}}>The standalone own-damage two-wheeler insurance policy gives coverage for the own-damages sustained by the insured bike due to an accident, theft, fire, natural disaster and man-made calamity. A standalone own-damage policy provides flexibility to policyholders for choosing an insurance company of their choice. The policyholders can buy a third-party policy and own-damage policy either from the same insurer or a different one. An insured individual can expand the coverage of a own-damage insurance policy by buying add-on covers.</label>
                </div>
                </div>
                
            </div>
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", marginTop:"10%", textAlign:"center"}}>
            <label style={{fontSize:30, color:"#E7393E"}}>Key highlights of Bike Insurance</label>
            <div style={{textAlign:"start"}}>
                <label style={{fontSize:17}}>Types of bike insurance</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>You can insure your bike with various types of bike insurance; it protects the vehicle owner in below situations.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}><b>Comprehensive bike insurance: </b> This type of bike insurance provides coverage against third party liability and own damages. It covers the bike owner against damages caused due to theft, natural or man made calamities and even third party.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}><b>Third party bike insurance: </b>  This type of insurance is mandatory as per the law, providing you with coverage against third party liability. This type of cover provides protection against death, disability or bodily injury caused to third person; it also covers property damages. Buying third party two wheeler insurance does not cover damages caused to your own bike.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}><b>Standalone own damage bike insurance:</b> As the name suggests, this type of insurance offers you coverage for damages caused due to theft, natural or man made calamities; it does not provide coverage against third party liabilities.</label><br/><br/>
            </div>
        </div>
        <Footer/>
    </div>
  )
}

export default BikeInsurance