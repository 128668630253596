import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import homeins from '../Homeins.png'
import privacy from '../privacy.png'
import daimond from '../daimond.png'
import cover from '../cover.png'
import running from '../running.png'
import painting from '../painting.png'
import world from '../world.png'
import building from '../building.png'

function HomeInsurance() {
  useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
        <Header/>
        <div style={{width:"100%", height:"20vh"}}></div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label className='homeinnslab' style={{fontSize:45, color:"#E7393E"}}>Home Insurance Online</label>
            <br/>
            <br/>
            <br/>
            <br/>
            <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
              <img className='hominsimg' src={homeins}></img>
            </div>
        </div>
<br/><br/><br/><br/>
        <div style={{width:"100%", minHeight:"20vh", textAlign:"center", backgroundColor:"#E7393E"}}>
            <label className='homeinnslab' style={{fontSize:45, color:"white"}}>TYPES OF HOME INSURANCE</label>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='resdiv12' style={{display:"flex", justifyContent:"space-evenly", alignItems:"center", flexWrap:"wrap"}}>
              <div className='resdiv13' style={{minHeight:"420px", width:"23%",  display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"space-evenly"}}>
                <div style={{height:"200px", width:"200px", borderRadius:"50%", backgroundColor:"white"}}>
                  <img  style={{height:"200px", width:"200px"}} src='https://www.bajajallianz.com/content/dam/bagic/home-insurance/My-Home-Insurance.png'></img>
                </div>
                <h3 style={{fontWeight:"bold", color:"white"}}>MY HOME</h3>
                <label style={{color:"white"}}>Home is where your heart belongs. Your house is the centre of your world, a priceless investment and a vault for a</label>
              </div>
              <div className='resdiv13' style={{minHeight:"420px", width:"23%",  display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"space-evenly"}}>
                <div style={{height:"200px", width:"200px", borderRadius:"50%", backgroundColor:"white"}}>
                  <img  style={{height:"200px", width:"200px"}} src='https://www.bajajallianz.com/content/dam/bagic/home-insurance/My-Home2.png'></img>
                </div>
                <h3 style={{fontWeight:"bold",color:"white"}}>HOUSEHOLDERS</h3>
                <label style={{color:"white"}}>Home is where your heart belongs. Your house is the centre of your world, a priceless investment and a vault for a</label>
              </div>
            </div>

        </div><br/>
            <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}> My Home</label>
            <br/>
            <br/>
            <div style={{textAlign:"center"}}>
              <h3>WE OFFER A WHOLE LOT WHEN IT COMES TO MY HOME INSURANCE POLICY</h3>
              <h3 style={{fontWeight:900}}>KEY FEATURES</h3><br/>
              <h4>My Home Insurance policy is applicable for all home owners, landlords, and tenants of a rented house with its range of features:</h4>
            </div><br/>
            <div className='reddivv11' style={{minHeight:"400px", width:"70%", marginLeft:"15%",  display:"flex", flexWrap:"wrap", gap:"5%"}}>
              <div className="resdivvv"  style={{width:"47.5%", height:"55px",  display:"flex", alignItems:"center",padding:"5px", gap:"3%", gap:"3%" }}>
                <div style={{height:50, width:50, borderRadius:"50%",backgroundColor:"#E7393E", display:"grid",placeItems:"center"}}>
                  <img style={{height:30, width:30}} src={privacy}></img>
                </div>
                  <label>Contents Cover</label>
              </div>
              <div className="resdivvv"  style={{width:"47.5%", height:"55px",  display:"flex", alignItems:"center",padding:"5px", gap:"3%"}}><div style={{height:50, width:50, borderRadius:"50%",backgroundColor:"#E7393E", display:"grid",placeItems:"center"}}>
                  <img style={{height:30, width:30}} src={cover}></img>
                </div>
                  <label>Portable equipment Cover</label>
                </div>
              <div className="resdivvv"  style={{width:"47.5%", height:"55px",  display:"flex", alignItems:"center",padding:"5px", gap:"3%"}}>
                <div style={{height:50, width:50, borderRadius:"50%",backgroundColor:"#E7393E", display:"grid",placeItems:"center"}}>
                  <img style={{height:30, width:30}} src={daimond}></img>
                  
                </div>
                  <label>Jewellery and valubles Cover</label>
                </div>
              <div className="resdivvv"  style={{width:"47.5%", height:"55px",  display:"flex", alignItems:"center",padding:"5px", gap:"3%"}}><div style={{height:50, width:50, borderRadius:"50%",backgroundColor:"#E7393E", display:"grid",placeItems:"center"}}>
                 
                  <img style={{height:30, width:30}} src={painting}></img>
                </div>
                  <label>Curios, Works of art and paintings cover</label>
                </div>
              <div className="resdivvv"  style={{width:"47.5%", height:"55px",  display:"flex", alignItems:"center",padding:"5px", gap:"3%"}}><div style={{height:50, width:50, borderRadius:"50%",backgroundColor:"#E7393E", display:"grid",placeItems:"center"}}>
                  <img style={{height:30, width:30}} src={running}></img>
                </div>
                  <label>Burglary Cover</label>
                </div>
              <div className="resdivvv"  style={{width:"47.5%", height:"55px",  display:"flex", alignItems:"center",padding:"5px", gap:"3%"}}><div style={{height:50, width:50, borderRadius:"50%",backgroundColor:"#E7393E", display:"grid",placeItems:"center"}}>
                  <img style={{height:30, width:30}} src={building}></img>
                </div>
                  <label>Building Cover</label>
                </div>
              <div className="resdivvv"  style={{width:"47.5%", height:"55px",  display:"flex", alignItems:"center",padding:"5px", gap:"3%"}}><div style={{height:50, width:50, borderRadius:"50%",backgroundColor:"#E7393E", display:"grid",placeItems:"center"}}>
                  <img style={{height:30, width:30}} src={world}></img>
                </div>
                  <label>Worldwide Cover</label>
                </div>
            </div>
        </div>



        <br/>
        <br/>
            <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:40, color:"#E7393E"}}> Householders</label>
            <br/>
            <br/>
            <div style={{textAlign:"center"}}>
              <h3>COMPREHENSIVE HOUSEHOLDER INSURANCE FOR YOU</h3>
              <h4>With Bajaj Allianz Householder Policy, other than an occasional coat of paint, any worries you might have about your home or property coverage will soon evaporate and you’ll feel financially more secure than ever before!</h4>
              <h3 style={{fontWeight:900}}>Key Benefits for Householder Insurance</h3><br/>
            </div><br/>
            <div className='reddivv11' style={{minHeight:"200px", width:"70%", marginLeft:"15%",  display:"flex", flexWrap:"wrap", gap:"5%"}}>
              <div className="resdivvv"  style={{width:"47.5%", height:"55px",  display:"flex", alignItems:"center",padding:"5px", gap:"3%" }}>
                <div style={{height:50, width:50, borderRadius:"50%",backgroundColor:"#E7393E", display:"grid",placeItems:"center"}}>
                  <img style={{height:30, width:30}} src={privacy}></img>
                </div>
                  <label>Protect your property</label>
              </div>
              <div className="resdivvv"  style={{width:"47.5%", height:"55px",  display:"flex", alignItems:"center",padding:"5px", gap:"1%"}}><div style={{height:50, width:65, borderRadius:"50%",backgroundColor:"#E7393E", display:"grid",placeItems:"center"}}>
                  <img style={{height:30, width:30}} src={cover}></img>
                </div>
                  <label>Protection to your domestic and electronic appliances</label>
                </div>
              <div className="resdivvv"  style={{width:"47.5%", height:"55px",  display:"flex", alignItems:"center",padding:"5px", gap:"1%"}}>
                <div style={{height:50, width:50, borderRadius:"50%",backgroundColor:"#E7393E", display:"grid",placeItems:"center"}}>
                  <img style={{height:30, width:30}} src={daimond}></img>
                  
                </div>
                  <label>Intrests of the insured and their<br/> family members in a single policy</label>
                </div>
         
            </div>
        </div>
        
        <Footer/>
    </div>
  )
}

export default HomeInsurance