import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyC3cYr9w2WN6I1VYHdA86qiz19j1kk8W6s",
  authDomain: "insurence-ef27c.firebaseapp.com",
  projectId: "insurence-ef27c",
  storageBucket: "insurence-ef27c.appspot.com",
  messagingSenderId: "873083581254",
  appId: "1:873083581254:web:cee1f6a625c3f4da556b47",
  measurementId: "G-DY77L45R4J"
};

firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();
export default storage;