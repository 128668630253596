import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import Search  from '../search.png'
import thunder  from '../thunder.png'
import heart  from '../heart.png'
import car  from '../car.svg'

function CarInsurance() {
    useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
        <Header/>
        <div style={{width:"100%", height:"15vh"}}></div>
        <div style={{width:"80%", minHeight:"50vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:30, color:"#E7393E"}}>What is Car Insurance?</label>
            <div className='car-div1' style={{display:"flex", justifyContent:"space-evenly", marginTop:"5%"}}>
                <div className='car-div1-sub' style={{width:"50%", textAlign:"justify"}}>
                    <label style={{fontSize:17, fontWeight:"lighter"}}>Car insurance is a contract between a car owner and a general insurance company wherein the latter promises to protect the car owner from financial losses which may occur due to an unfortunate event involving his/her car. Depending on the scope of coverage, there are 3 types of car insurance policies - Third-Party Car Insurance, Standalone Own-Damage (OD) Car Insurance, and Comprehensive Car Insurance. To continue to avail the benefits of these policies, the car insurance renewal should be processed on time by policyholders.</label>    
                </div>
                <div className='car-div1-sub' style={{width:"30%"}}>
                    <img src={car}/>
                </div>
            </div>
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", marginTop:"10%", textAlign:"center"}}>
            <label style={{color:"#E9373E", fontSize:30}}>Types of Car Insurance</label>     
            <div className='car-div2' style={{width:"100%", display:"flex", justifyContent:"space-between", marginTop:"5%", marginBottom:"5%"}}>
                <div className='car-div2-sub' style={{boxShadow:"0px 0px 5px grey" ,width:"30%", minHeight:"80vh", textAlign:"start", padding:"2%"}}>
                    <img width="15%" src={thunder}/><br/>
                    <b style={{fontSize:15}}>Comprehensive car Insurance </b>
                    <div style={{textAlign:"justify"}}>
                    <br/><label style={{fontSize:15, fontWeight:"lighter"}}>A comprehensive car insurance policy is the most extensive car insurance cover under which an insurance company is liable to pay for financial liabilities incurred towards a third-party as well as for the own-damages sustained by the insured’s car. In addition to road accidents, the policy is applicable in the event of natural calamities, man-made disasters, self-ignition or lightning, explosion, fire, theft, etc</label>
                </div>
                </div>
                
            <div className='car-div2-sub' style={{boxShadow:"0px 0px 5px grey" ,width:"30%", minHeight:"80vh", textAlign:"start", padding:"2%"}}>
                    <img width="15%" src={Search}/><br/>
                    <b style={{fontSize:15}}>Third Party car Insurance</b>
                    <div style={{textAlign:"justify"}}>
                    <br/><label style={{fontSize:15, fontWeight:"lighter"}}>Third-party car insurance policy is a mandatory requirement for driving a car in India. It is the most basic car insurance plan and is thus also called Liability Only Car Insurance Policy. Under this cover, the insurance company is liable to pay for the bodily injuries, permanent disability, temporary disability as well as death of the third party caused by an accident involving the insured’s car. This plan also provides coverage for property damage incurred by the third party up to Rs. 7.5 Lakh.</label>
                </div>
                </div>
                
            <div className='car-div2-sub' style={{boxShadow:"0px 0px 5px grey" ,width:"30%", minHeight:"80vh", textAlign:"start", padding:"2%"}}>
                    <img width="15%" src={heart}/><br/>
                    <b style={{fontSize:15}}>Own-Damage car Insurance</b>
                    <div style={{textAlign:"justify"}}>
                    <br/><label style={{fontSize:15, fontWeight:"lighter"}}>the Insurance Regulatory and Development Authority of India (IRDAI) introduced the Standalone Own-Damage car insurance policy. Under this plan, the insured gets insurance coverage only for the own-damages sustained by his/her four-wheeler in a road accident, natural/man-made calamity, fire, explosion, theft or any other mishap. A standalone own-damage car insurance policy aims at offering the insured the flexibility in terms of the insurance provider they want to choose.</label>
                </div>
                </div>
                
            </div>
        </div>
        <div style={{width:"80%", minHeight:"20vh", marginLeft:"10%", marginTop:"10%", textAlign:"center"}}>
            <label style={{fontSize:30, color:"#E7393E"}}>Benefits of Buying Car Insurance?</label><br/><br/>
        <div style={{textAlign:"start", fontSize:17}}>
            <label style={{color:"grey"}}>A car insurance offers a host of benefits to its policyholders. The key benefits are as follows -</label><br/><br/>
            <div style={{paddingLeft:"3%"}}>
                <b style={{fontSize:17}}>• &nbsp; Helps You Stay Legally Compliant</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>A car insurance helps you stay legally compliant as according to Indian Motor Tariff, every car owner is mandatorily required to have at least a third-party car insurance policy. The absence of which is punishable with a fine of Rs. 2,000 and/or imprisonment of up to 3 months.</label>
            </div><br/>
            <div style={{paddingLeft:"3%"}}>
                <b style={{fontSize:17}}>• &nbsp; Protection Against Third-Party Liabilities</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>A car insurance policy helps you mitigate third-party liabilities such as bodily injuries, temporary/permanent disability, death as well as property damage. While, the compensation of third-party property damage is limited to Rs. 7.5 Lakh, there is no compensation cap in case third party bodily injuries, disability or death.</label>
            </div><br/>
            <div style={{paddingLeft:"3%"}}>
                <b style={{fontSize:17}}>• &nbsp; Protection Against Own-Damages</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>A car insurance policy helps you keep yourself financially secured against the own-damage expenses of your car. Damages incurred in road accidents, natural calamities, man-made disasters, fire, explosion, vandalism, etc are covered under standalone own-damage as well as comprehensive car insurance plans.</label>
            </div><br/>
            <div style={{paddingLeft:"3%"}}>
                <b style={{fontSize:17}}>• &nbsp; Coverage Against Theft</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>A car insurance policy also provides coverage in case of theft of your car. In case your car gets stolen, your insurance company provides you with your car’s Insured Declared Value (IDV) which corresponds to the market price of your car.</label>
            </div><br/>
            <div style={{paddingLeft:"3%"}}>
                <b style={{fontSize:17}}>• &nbsp; Personal Accident Cover</b> 
                <label style={{fontSize:16,marginLeft:"1.8%", fontWeight:"lighter", textAlign:"justify"}}>With a car insurance policy, you can also get a personal accident cover of up to Rs. 15 Lakh which offers a coverage in case of death, bodily injuries as well as disability of the policyholder in a car accident.</label>
            </div>
        </div>
        </div>
        <Footer/> 
    </div>
  )
}

export default CarInsurance