import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'

function TermInsurance() {
    useEffect(()=>{window.scrollTo(0,0)},[])
  return (
    <div>
        <Header/>
        <div style={{width:"100%", height:"15vh"}}></div>
        <div style={{width:"80%", minHeight:"50vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:30, color:"#E7393E"}}> What is Term Insurance ?</label>
            <div style={{textAlign:"justify"}}><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Term insurance is a type of life insurance policy that offers coverage to the policyholder for a fixed period of time. If the insured dies within the specified tenure then the nominee receives the sum insured i.e. the coverage amount. On the other hand, if the insured outlives the term, then the policy does not offer any coverage to the nominee and even the policyholder, except in case of return of premium term plan.
                You can purchase term insurance online to secure your family’s financial future at an affordable premium. Term plan is one of the most affordable types of life insurance. With this type of life insurance, your family would be able to continue with the same lifestyle they are leading now in your presence and even manage to pay off your existing liabilities using the sum assured amount.</label>
            </div>
        </div>
        <div style={{width:"80%", minHeight:"50vh", marginLeft:"10%", textAlign:"center"}}>
            <label style={{fontSize:30, color:"#E7393E"}}> How does term insurance work ?</label>
            <div style={{textAlign:'start'}}><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>Understanding how term insurance works is simple. Read on.</label>
            </div>
            <br/>
            <div style={{textAlign:"start", paddingLeft:"3%"}}>
                <label style={{fontSize:17, fontWeight:"lighter"}}>•   You buy a term insurance policy of a specific sum assured and period of time for securing your family’s financial future.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>•   On your death, a lump sum amount is payable by the insurance company to your family in case you pass away anytime during the policy tenure.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>•   To enjoy the life cover, it is necessary that you pay the premiums on time.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>•   In case if nothing happens to you during the policy term then the sum assured is not paid to you and the nominee either.</label><br/><br/>
                <label style={{fontSize:17, fontWeight:"lighter"}}>•   To enhance coverage, you can choose to buy various term insurance add-ons.</label>
            </div>
        </div>
        <br/><br/><br/><br/>
        <div style={{width:"80%", minHeight:"50vh", marginLeft:"10%", textAlign:"center"}}>
        <label style={{fontSize:30, color:"#E7393E"}}> Reasons To Invest In Term Insurance </label><br/><br/>
        <div style={{textAlign:"justify"}}><br/><br/>   
            <label style={{fontSize:17, fontWeight:"lighter"}}>Term insurance plans are the basic type of life insurance policy which are very cost effective and help a person in securing a financially protected future for their loved ones. Below mentioned are some reasons why you should invest in a term insurance policy:</label><br/><br/>
            <label style={{fontSize:17, fontWeight:"lighter"}}><b>Affordable Premiums: </b> According to the nature of the coverage provided under a term insurance policy the premiums of a term insurance policy are quite affordable. An individual can avail high coverage at nominal premiums rates. It is also advised to purchase a term insurance policy at a young age because older people are more prone to diseases and require more coverage which increases the premium.</label><br/><br/>
            <label style={{fontSize:17, fontWeight:"lighter"}}><b>Helps in Tax Savings: </b> A major advantage of purchasing a term insurance plan is that it can help you save taxes. Premium paid for a term insurance policy qualify for tax benefits under Section 80C of the Income Tax Act for up to Rs. 1.5 Lakh. Death/Maturity under a term insurance policy are also tax free according to the Section 10(10D) of the Income Tax Act.</label><br/><br/>
            <label style={{fontSize:17, fontWeight:"lighter"}}><b>Option to Add Riders: </b> Under a term insurance policy you can add additional coverages known as riders which help in increasing the scope of coverage under a term insurance policy. The riders can be added to the term insurance policy in exchange of additional premium. Some commonly opted life insurance riders are Accidental Death Benefit, Accidental Total and Permanent Disability, Critical Illness Rider, Waiver of Premium Rider etc.</label><br/><br/>
            <label style={{fontSize:17, fontWeight:"lighter"}}><b> Provides Financial Security:</b>  A term insurance policy is known as pure protection plans which help in providing financial security to the family of the life assured in case of an unforeseen demise of the life assured during the coverage period. The death benefit shall be provided to the nominee in case of untimely demise of the life assured which can help the family members of the life assured to fulfil their financial requirements in case the life assured is not around.</label><br/><br/>
        </div>
        </div><br/><br/><br/><br/>
        <Footer/>
    </div>
  )
}

export default TermInsurance