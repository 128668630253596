import React, { useState } from 'react'
import header from './header.css'
import { useNavigate } from 'react-router-dom';
import isilogo from '../ISI.png'
import caricon from '../caricon.png'
import bikeicon from '../bikeicon.png'
import healthicon from '../healthicon.png'
import lifeicon from '../lifeicon.png'
import travelicon from '../travelicon.png'
import peticon from '../peticon.png'
import termicon from '../termicon.png'
import truckicon from '../truckicon.png'
import homeicon from '../homeicon.png'
import govicon from '../govicon.png'

function Header() {
    const navigate = useNavigate()
    const [insDiv, setInsDiv] = useState(false);
    const [invtDiv, setInvtDiv] = useState(false);

    const [shownav, setShownav] = useState(false)
    const [navdiv, setNavdiv] = useState(0)

    const instagram = ()=>{ window.open("https://instagram.com/insurance_specialist.india?igshid=ZGUzMzM3NWJiOQ==")}
    const facebook = ()=>{ window.open("https://www.facebook.com/amiteshkale8?mibextid=ZbWKwL")}
  return (
    <div>
        <div className='header'>
            <div style={{width:"20%"}} onClick={()=>navigate("/")}>
            <img style={{width:"80%", marginLeft:"20%", cursor:"pointer"}} src={isilogo}/>
            </div>       
            <div style={{width:"30%"}}
            onMouseOver={()=>{setInsDiv(false); setInvtDiv(false)}}></div>     
            <div style={{width:"50%",height:"100%",display:"flex", alignItems:"center", justifyContent:"space-evenly" }}>
                <div onMouseOver={()=>{setInsDiv(true); setInvtDiv(false)}} >
                    <label className='head_label' style={{fontSize:17, cursor:"pointer"}}>Insurance     <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </label>
                </div>

               
                <div onMouseOver={()=>{setInsDiv(false); setInvtDiv(true)}} >
                    <label className='head_label' style={{fontSize:17, cursor:"pointer"}}>Investment    <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </label>
                </div>
                <div onMouseOver={()=>setInvtDiv(false)} onClick={()=>navigate("/aboutus")}> 
                    <label className='head_label' style={{fontSize:17, cursor:"pointer"}}>About Us   
                    </label>
                </div>
                <div >
                    <label className='head_label' style={{fontSize:17, cursor:"pointer"}} onClick={()=>navigate("/Contact")}>Contact Us</label>
                </div>
            </div> 
            {/* INSURANCE DIV */}
            <div className='scroll_div' style={{width:"100%", height:insDiv?500:0, backgroundColor:"white", transition:"0.5s", position:"absolute", top:70, left:0,
             overflow:"hidden", display:"flex", flexWrap:"wrap",gap:"1%", zIndex:1, overflowY:"auto"}} onMouseLeave={()=>setInsDiv(false)}>
                    <div style={{width:"24%", height:290, backgroundColor:"white",marginLeft:"0.5%", marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                        <img style={{ width: 40 }} src={healthicon}/>
                        <b style={{fontSize:17, marginLeft:"5%", color:"#E9373E", cursor:'pointer'}} onClick={()=>navigate("/healthinsurance")}>HEALTH INSURANCE</b>
                        </div>
                        <div>
                           
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Mediclaim Policy</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Best Health Insurance Plan</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance Plans for family</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance for Senior Citizens</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance for Parents</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Women Health Insurance </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance for Children</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Super Top-Up health insurance </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance Premium Calculator</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance Claim Settlement Ratio</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Critical Illness Insurance Policy</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>1 Crore Health Insurance </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance Premium</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Pradhan Mantri Suraksha Bima Yojana</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Rashtriya Swasthya Bima Yojana</label></div>
                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white", marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                       
                        <img style={{width:40}} src={lifeicon} ></img>
                        <b style={{fontSize:17, marginLeft:"5%", color:"#E9373E", cursor:"pointer",}} onClick={()=>navigate("/lifeinsurance")}>LIFE INSURANCE</b>
                        </div>
                        <div>
                           
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Plans</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Postal Life Insurance </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Term Insurance vs Life Insurance </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Quotes </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Premium </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Benefits </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Coverage </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Premium Calculator </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Tax Benefit on Life Insurance  </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Human Life Value Calculator  </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Claim Settlement Ratio </label></div>

                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white", marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                        
                        <img style={{width:40}} src={caricon} ></img>
                        <b style={{fontSize:17, marginLeft:"5%", color:"#E9373E", cursor:'pointer'}} onClick={()=>navigate("/carinsurance")}>CAR INSURANCE</b>
                        </div>
                        <div>
                           
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Pay As You Drive</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Cashless Garages</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Standalone Own Damage</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Quotes</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Renewal</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Premium Calculator</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Comprehensive</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Zero Depreciation</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Third Party Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Add on Cover</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Claim Settlement</label></div>

                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white", marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                   
                        <img style={{width:40}} src={bikeicon} ></img>
                        <b style={{fontSize:17, marginLeft:"5%", color:"#E9373E", cursor:"pointer"}} onClick={()=>navigate("/bikeinsurance")}>BIKE INSURANCE</b>
                        </div>
                        <div>
                       
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Cashless Garages</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Standalone Own Damage</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Quotes</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Renewal</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Premium Calculator</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Comprehensive</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Zero Depreciation</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Third Party Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Add on Cover</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Claim Settlement</label></div>

                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white", marginLeft:"0.5%", marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                
                        <img style={{width:40}} src={termicon} ></img>
                        <b style={{fontSize:17, marginLeft:"5%", color:"#E9373E", cursor:"pointer"}} onClick={()=>navigate("/terminsurance")}>TERM INSURANCE</b>
                        </div>
                        <div>
                  
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Term Insurance Plan</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Term Insurance Premium Calculator</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Tax Benefits of Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Group of Term Life Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Family Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Senior Citizen Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Spouse Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Term Plans For NRI</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Term Insurance For Non Working Spouse</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>50 Lakh Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>1 Crore Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>2 Crore Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>5 Crore Term Insurance</label></div>

                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white",  marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
               
                        <img style={{width:40}} src={travelicon} ></img>
                        <b style={{fontSize:17, marginLeft:"5%", color:"#E9373E",cursor:"pointer"}} onClick={()=>navigate("/travelinsurance")}>TRAVEL INSURANCE</b>
                        </div>
                        <div>
                 
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Travel To Companies</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Types Of Travel Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Why should you get a Travel Insurance</label></div>
                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white",marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                  
                        <img style={{width:40}} src={peticon}/>
                        <b style={{fontSize:17, marginLeft:"5%", color:"#E9373E", cursor:"pointer"}} onClick={()=>navigate("/petinsurance")}>PET INSURANCE</b>
                        </div>
                        <div>
                      
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Pet Clothing</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Pet Healthcare</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Pet Nutrition</label></div>
                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white",marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                  
                        <img style={{width:40}} src={truckicon}/>
                        <b style={{fontSize:17, marginLeft:"5%", color:"#E9373E", cursor:"pointer"}} onClick={()=>navigate("/CommercialInsurance")}>COMMERCIAL INSURANCE</b>
                        </div>
                        <div>
                      
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Property Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Marine Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Liability Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Financial Lines Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Engineering Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Group Mediclaim Insurance</label></div>
                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white", marginLeft:"0.5%", marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                  
                        <img style={{width:40}} src={bikeicon}/>
                        <b style={{fontSize:17, marginLeft:"5%", color:"#E9373E", cursor:"pointer"}} onClick={()=>navigate("/EVInsurance")}>EV INSURANCE</b>
                        </div>
                        <div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Electric Bike Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Electric Car Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Electric Commercial Vehicle Insurance</label></div>
                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white",marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                  
                        <img style={{width:40}} src={homeicon}/>
                        <b style={{fontSize:17, marginLeft:"5%", color:"#E9373E",cursor:"pointer"}} onClick={()=>navigate("/HomeInsurance")}>HOME INSURANCE</b>
                        </div>
                        <div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>My Home Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Householder Policy</label></div>
                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white",marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                  
                        <img style={{width:40}} src={govicon}/>
                        <b style={{fontSize:17, marginLeft:"5%", color:"#E9373E", cursor:"pointer"}} onClick={()=>navigate("/GovScheme")}>GOVERNMENT SCHEMES</b>
                        </div>
                        <div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Pradhan Mantri Fasal Bima Yojana</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Pradhan Mantri Suraksha Bima Yojana</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Weather Based Crop Insurance Scheme</label></div>
                        </div>
                    </div>
            </div>
            {/* INVESTMENT DIV */}
        <div className='scroll_div' style={{width:"100%", height:invtDiv?400:0, backgroundColor:"white", transition:"0.5s", position:"absolute", top:70, left:0,
             overflow:"hidden", display:"flex", justifyContent:"space-evenly", zIndex:1}} onMouseLeave={()=>setInvtDiv(false)}>
                 <div style={{width:"15%", height:"300px", marginTop:"5%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <b style={{color:"#E9373E", fontSize:17}}>Investment Services</b>
                    <div style={{width:"100%", height:"80%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <label>Brokerage</label>
                    <label>Education Savings</label>
                    <label>Mutaul Funds</label>
                    </div>
                 </div>
                 <div style={{width:"15%", height:"300px", marginTop:"5%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <b style={{color:"#E9373E", fontSize:17}}>Retirement</b>
                    <div style={{width:"100%", height:"80%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <label>Individual Retirement</label>
                    <label>Business Retirement</label>
                    </div>
                 </div>
                 <div style={{width:"15%", height:"300px", marginTop:"5%", display:"flex",gap:"5%", flexDirection:"column"}}>
                    <b style={{color:"#E9373E", fontSize:17}}>Resources</b>
                    <div style={{width:"100%", height:"80%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <label>Market Insights</label>
                    <label>Tax Center</label>
                    <label>Rollovers</label>
                    </div>
                 </div>
                 <div style={{width:"15%", height:"300px", marginTop:"5%", display:"flex",gap:"5%", flexDirection:"column"}}>
                    <b style={{color:"#E9373E", fontSize:17}}>Estate Planning</b>
                    <div style={{width:"100%", height:"80%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <label>Trusts</label>
                    <label>Joint Accounts</label>
                    <label>Life Insurance</label>
                    <label>Federal Estate Tax</label>
                    <label>Estate Planning Tools</label>
                    </div>
                 </div>
                 <div style={{width:"15%", height:"300px", marginTop:"5%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <b style={{color:"#E9373E", fontSize:17}}>Annuities</b>
                    <div style={{width:"100%", height:"82%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <label>What is an Annuity</label>
                    <label>The Basics</label>
                    <label>Guaranteed Income Now</label>
                    <label>Guaranteed Income Later</label>
                    <label>Future Income Plus</label>
                    <label>Change an Annuity</label>
                    </div>
                 </div>
        </div>
        </div>
 


        <div className='responsive_header'> 
            <img width="50%" src={isilogo} onClick={()=>navigate("/")}/>
            <i class="fa fa-bars fa-2x" aria-hidden="true" onClick={()=>setShownav(!shownav)}></i>
        </div>
        <div style={{width:"90%", height:shownav?"70vh":0, transition:"0.5s", paddingTop:"2%",marginTop:"-5%", paddingLeft:shownav?"3%":0, 
        display:"flex", flexDirection:"column", position:"absolute", zIndex:3, right:0, backgroundColor:"white", overflow:"hidden"}}>
            
            <label style={{fontSize:25, color:navdiv===1?"#E7393E":"black", marginTop:"2%"}} onClick={()=>setNavdiv(1)}>Insurance</label>
            <div style={{height:navdiv===1?"280px":0, display:"flex", flexDirection:"column", marginLeft:"5%", overflow:"hidden", transition:"0.5s"}}>
                <label onClick={()=>navigate("/healthinsurance")}>Health Insurance</label>
                <label onClick={()=>navigate("/lifehinsurance")}>Life Insurance</label>
                <label onClick={()=>navigate("/terminsurance")}>Team Insurance</label>
                <label onClick={()=>navigate("/bikeinsurance")}>Bike Insurance</label>
                <label onClick={()=>navigate("/carinsurance")}>Car Insurance</label>
                <label onClick={()=>navigate("/travelinsurance")}>Travel Insurance</label>
                <label onClick={()=>navigate("/petinsurance")}>Pet Insurance</label>
                <label onClick={()=>navigate("/")}>Commercial Insurance</label>
                <label onClick={()=>navigate("/")}>EV Insurance</label>
                <label onClick={()=>navigate("/")}>Home Insurance</label>
                <label onClick={()=>navigate("/")}>Government Scheme</label>
            </div>
            <label style={{fontSize:25, color:navdiv===2?"#E7393E":"black"}} onClick={()=>setNavdiv(2)}>Investment</label>
            <div style={{height:navdiv===2?"200px":0, display:"flex", flexDirection:"column", marginLeft:"5%", overflow:"hidden", transition:"0.5s"}}>
                <label>Investment Services</label>
                <label>Retirement</label>
                <label>Resources</label>
                <label>Estate Planning</label>
                <label>Annuities</label>
            </div>
            <label style={{fontSize:25,}} onClick={()=>navigate("/aboutus")}> About Us</label>
            <label style={{fontSize:25,}} onClick={()=>navigate("/contact")}> Contact Us</label>
            
            <div style={{display:"flex", flexDirection:"column", fontSize:17, marginTop:"10%"}}>
                <a style={{color:"black"}} href="amiteshkale8@gmail.com">amiteshkale8@gmail.com</a>
                <a style={{color:"black"}} href="tel:+919039937352">+91 90399 37352</a>
                <a style={{color:"black"}} href="tel:+919826017352">+91 98260 17352</a>
            </div>
            <br/>
            <div style={{display:"flex", gap:"5%", fontSize:25}}>
                <i onClick={instagram} class="fa fa-instagram" aria-hidden="true"></i>
                <i onClick={facebook} class="fa fa-facebook-square" aria-hidden="true"></i>
                <i class="fa fa-telegram" aria-hidden="true"></i>
            </div>
        </div>

{/* ---------------------------------------- */}

        {/* INSURANCE DIV */}
        {/* <div className='scroll_div' style={{width:"100%", height:insDiv?700:0, backgroundColor:"white", transition:"0.5s", position:"absolute", top:70,
             overflow:"hidden", display:"flex", flexWrap:"wrap",gap:"1%", zIndex:1}}>
                    <div style={{width:"24%", height:290, backgroundColor:"white",marginLeft:"0.5%", marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                        <img style={{ width: 40 }} src="https://www.insurancedekho.com/pwa/img/v2_icon_health.svg"/>
                        <b style={{fontSize:15, marginLeft:"5%"}}>HEALTH INSURANCE</b>
                        </div>
                        <div>
                           
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Mediclaim Policy</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Best Health Insurance Plan</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance Plans for family</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance for Senior Citizens</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance for Parents</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Women Health Insurance </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance for Children</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Super Top-Up health insurance </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance Premium Calculator</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance Claim Settlement Ratio</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Critical Illness Insurance Policy</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>1 Crore Health Insurance </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Health Insurance Premium</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Pradhan Mantri Suraksha Bima Yojana</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Rashtriya Swasthya Bima Yojana</label></div>
                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white", marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                       
                        <img style={{width:40}} src="https://static.insurancedekho.com/pwa/img/life_insurance.svg" ></img>
                        <b style={{fontSize:15, marginLeft:"5%"}}>LIFE INSURANCE</b>
                        </div>
                        <div>
                           
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Plans</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Postal Life Insurance </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Term Insurance vs Life Insurance </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Quotes </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Premium </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Benefits </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Coverage </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Premium Calculator </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Tax Benefit on Life Insurance  </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Human Life Value Calculator  </label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Life Insurance Claim Settlement Ratio </label></div>

                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white", marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                        
                        <img style={{width:40}} src="https://static.insurancedekho.com/pwa/img/v2_icon_car.svg" ></img>
                        <b style={{fontSize:15, marginLeft:"5%"}}>CAR INSURANCE</b>
                        </div>
                        <div>
                           
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Pay As You Drive</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Cashless Garages</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Standalone Own Damage</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Quotes</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Renewal</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Premium Calculator</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Comprehensive</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Zero Depreciation</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Third Party Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Add on Cover</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Claim Settlement</label></div>

                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white", marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                   
                        <img style={{width:40}} src="https://static.insurancedekho.com/pwa/img/v2_icon_bike.svg" ></img>
                        <b style={{fontSize:15, marginLeft:"5%"}}>BIKE INSURANCE</b>
                        </div>
                        <div>
                       
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Cashless Garages</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Standalone Own Damage</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Quotes</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Renewal</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Premium Calculator</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Comprehensive</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Zero Depreciation</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Third Party Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Add on Cover</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Claim Settlement</label></div>

                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white", marginLeft:"0.5%", marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                
                        <img style={{width:40}} src="https://static.insurancedekho.com/pwa/img/v2_icon_bike.svg" ></img>
                        <b style={{fontSize:15, marginLeft:"5%"}}>TERM INSURANCE</b>
                        </div>
                        <div>
                  
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Term Insurance Plan</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Term Insurance Premium Calculator</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Tax Benefits of Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Group of Term Life Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Family Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Senior Citizen Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Spouse Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Term Plans For NRI</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Term Insurance For Non Working Spouse</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>50 Lakh Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>1 Crore Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>2 Crore Term Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>5 Crore Term Insurance</label></div>

                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white",  marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
               
                        <img style={{width:40}} src="https://static.insurancedekho.com/pwa/img/v2_icontravel.svg" ></img>
                        <b style={{fontSize:15, marginLeft:"5%"}}>TRAVEL INSURANCE</b>
                        </div>
                        <div>
                 
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Travel To Companies</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Types Of Travel Insurance</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Why should you get a Travel Insurance</label></div>
                        </div>
                    </div>
                    <div style={{width:"24%", height:290, backgroundColor:"white",marginTop:"3%", padding:"1%", position:"relative", overflowY:"scroll", OverflowStyle:"none"}}>
                       <div style={{position:"sticky", top:-15, backgroundColor:"white"}}>
                  
                        <img style={{width:40}} src="https://www.insurancedekho.com/pwa/img/v2_iconpet.svg"/>
                        <b style={{fontSize:15, marginLeft:"5%"}}>PET INSURANCE</b>
                        </div>
                        <div>
                      
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Pet Clothing</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Pet Healthcare</label></div>
                            <div style={{width:"90%", marginLeft:"10%", marginTop:"1%", borderBottom:"1px solid lightgrey"}}><label>Pet Nutrition</label></div>
                        </div>
                    </div>
        </div> */}
        {/* INVESTMENT DIV */}
        {/* <div className='scroll_div' style={{width:"100%", height:invtDiv?400:0, backgroundColor:"white", transition:"0.5s", position:"absolute", top:70,
             overflow:"hidden", display:"flex", justifyContent:"space-evenly", zIndex:1}}>
                 <div style={{width:"15%", height:"300px", marginTop:"5%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <b style={{color:"#E9373E", fontSize:17}}>Investment Services</b>
                    <div style={{width:"100%", height:"80%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <label>Brokerage</label>
                    <label>Education Savings</label>
                    </div>
                 </div>
                 <div style={{width:"15%", height:"300px", marginTop:"5%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <b style={{color:"#E9373E", fontSize:17}}>Retirement</b>
                    <div style={{width:"100%", height:"80%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <label>Individual Retirement</label>
                    <label>Business Retirement</label>
                    </div>
                 </div>
                 <div style={{width:"15%", height:"300px", marginTop:"5%", display:"flex",gap:"5%", flexDirection:"column"}}>
                    <b style={{color:"#E9373E", fontSize:17}}>Resources</b>
                    <div style={{width:"100%", height:"80%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <label>Market Insights</label>
                    <label>Tax Center</label>
                    <label>Rollovers</label>
                    </div>
                 </div>
                 <div style={{width:"15%", height:"300px", marginTop:"5%", display:"flex",gap:"5%", flexDirection:"column"}}>
                    <b style={{color:"#E9373E", fontSize:17}}>Estate Planning</b>
                    <div style={{width:"100%", height:"80%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <label>Trusts</label>
                    <label>Joint Accounts</label>
                    <label>Life Insurance</label>
                    <label>Federal Estate Tax</label>
                    <label>Estate Planning Tools</label>
                    </div>
                 </div>
                 <div style={{width:"15%", height:"300px", marginTop:"5%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <b style={{color:"#E9373E", fontSize:17}}>Annuities</b>
                    <div style={{width:"100%", height:"82%", display:"flex", gap:"5%", flexDirection:"column"}}>
                    <label>What is an Annuity</label>
                    <label>The Basics</label>
                    <label>Guaranteed Income Now</label>
                    <label>Guaranteed Income Later</label>
                    <label>Future Income Plus</label>
                    <label>Change an Annuity</label>
                    </div>
                 </div>
        </div> */}
    </div>
  )
}

export default Header